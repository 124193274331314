import {TitleWGap} from "../../components/Typo";
import {Map} from '../../components/Map'

export const PaymentDelivery = () => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});

    return <>
        <div className="container container-pd payment-delivery">
            <TitleWGap black={'Оплата '} green={"и предзаказ"}/>
            <br/>
            <br/>
            <br/>
            <p className={'text'}>Заказ в Koreana Light стал еще удобнее и безопаснее!</p> <br/>
            <p className={'text'}><strong>Чтобы не стоять в очереди, оформляйте предзаказ блюд на нашем сайте онлайн.</strong></p>
            <p className={'text'}><strong>Всего 15 минут </strong> - и Вы сразу можете насладиться корейской кухней в одном из наших ресторанов или забрать заказ с собой.</p>
            <p className={'text'}>Оформляйте заказ на сайте и выбирайте удобный Вам ресторан!</p>
        </div>
        <div className="container-fluid restaurants-page__map">
            <div className="restaurants-page__map--holder">
                <Map/>
            </div>
        </div>
    </>
};
