import React, {useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {TitleWGap} from "../Typo";
import {Button} from '../Buttons'

import main from '../../static/images/banner/inner/main.png'
import s1 from '../../static/images/banner/inner/S1.webp'
import s2 from '../../static/images/banner/inner/S2.png'
import s3 from '../../static/images/banner/inner/S3.png'

import arrow_left from '../../static/icons/arrow_left.svg'
import arrow_right from '../../static/icons/arrow_right.svg'

import {AppContext} from "../../store/context";

const texts = {
    0: {
        title: 'ПИБИМПАБЫ',
        descriptions: 'Главный хит корейской кухни. Еще вкуснее - еще полезнее.',
        link: '/menu/pibimpabi'
    },
    1: {
        title: 'БУРГЕР КИМ',
        descriptions: 'Оригинальные бургеры с булочкой из риса, курицей, овощами и сыром',
        link: '/menu/byrger-kim'
    },
    2: {
        title: 'КИМПАБЫ',
        descriptions: 'Корейский ролл с различной начинкой, овощами и омлетом',
        link: '/menu/kimpabi'
    },
    3: {
        title: 'КУКСУ',
        descriptions: 'Холодный суп с лапшой, говядиной, салатами и омлетом',
        link: '/menu/sypi'
    },
}

export const MainBanner = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const [activeItem, setActiveItem] = useState(0);

    const images = {
        0: main,
        1: s2,
        2: s3,
        3: s1
    }


    const shifts = {
        0: {
            first: [-260, -386],
            second: [-470, 0],
            third: [-270, 0],
            fourth: [-70, 0]
        },
        1: {
            first: [-470, 0],
            second: [-260, -386],
            third: [-270, 0],
            fourth: [-70, 0]
        },
        2: {
            first: [-470, 0],
            second: [-270, 0],
            third: [-260, -386],
            fourth: [-70, 0]
        },
        3: {
            first: [-470, 0],
            second: [-270, 0],
            third: [-70, 0],
            fourth: [-260, -386]
        }
    };

    const prev = () => {
        if(activeItem === 0) {
            setActiveItem(3);

            return;
        }

        setActiveItem(activeItem - 1)
    };

    const next = () => {
        if(activeItem === 3) {
            setActiveItem(0);

            return;
        }

        setActiveItem(activeItem + 1)
    };

    useEffect(
        () => {
            const id = setInterval(() => next(), 3000);
            return () => clearInterval(id);
        },
        [activeItem]
    );

    return <>
        <div className="inner">
            <div className="main-banner__col">
                <div className="main-banner__text">
                    <TitleWGap black={'гармония вкуса'} green={'и пользы'}/>
                    <div className="subtitle text--handwritten">
                        корейской кухни
                    </div>
                </div>
                {context.settings.isMobile
                    ? <div className="main-banner__image"><img src={images[activeItem]} alt=""/></div>
                    :
                    <>
                        <div onClick={() => {prev()}} className="main-banner-arrow main-banner-arrow--left">
                            <img src={arrow_left} alt=""/>
                        </div>
                        <MainBannerCard active={activeItem}/>
                        <div onClick={() => {next()}} className="main-banner-arrow main-banner-arrow--right">
                            <img src={arrow_right} alt=""/>
                        </div>
                    </>
                }
            </div>
            {context.settings.isMobile
                ? <MainBannerCard active={activeItem}/>
                : <div className="main-banner__col images">
                    <MainBannerImage/>
                    <div className="main-banner__product-row">
                        <div
                            style={{transform: `translate(${shifts[activeItem].first[0]}px, ${shifts[activeItem].first[1]}px)`}}
                            onClick={() => setActiveItem(0)}
                            className={`main-banner__product-row-item banner-first ${activeItem === 0 ? 'active' : ''}`}>
                            <img onClick={() => {return activeItem === 0 ? history.push(texts[0].link) : null}} src={main} alt=""/>
                        </div>
                        <div
                            style={{transform: `translate(${shifts[activeItem].second[0]}px, ${shifts[activeItem].second[1]}px)`}}
                            onClick={() => setActiveItem(1)}
                            className={`main-banner__product-row-item banner-second ${activeItem === 1 ? 'active' : ''}`}>
                            <img onClick={() => {return activeItem === 1 ? history.push(texts[1].link) : null}} src={s2} alt=""/>
                        </div>
                        <div
                            style={{transform: `translate(${shifts[activeItem].third[0]}px, ${shifts[activeItem].third[1]}px)`}}
                            onClick={() => setActiveItem(2)}
                            className={`main-banner__product-row-item banner-third ${activeItem === 2 ? 'active' : ''}`}>
                            <img onClick={() => {return activeItem === 2 ? history.push(texts[2].link) : null}} src={s3} alt=""/>
                        </div>
                        <div
                            style={{transform: `translate(${shifts[activeItem].fourth[0]}px, ${shifts[activeItem].fourth[1]}px)`}}
                            onClick={() => setActiveItem(3)}
                            className={`main-banner__product-row-item banner-fourth ${activeItem === 3 ? 'active' : ''}`}>
                            <img onClick={() => {return activeItem === 3 ? history.push(texts[3].link) : null}} src={s1} alt=""/>
                        </div>
                    </div>
                </div>}
        </div>
    </>
};

const MainBannerCard = ({active}) => {
    const history = useHistory();


    return <div className="main-banner__card">
        <div className="card">
            <div className="card__title" onClick={() => {history.push(texts[active].link)}}>{texts[active].title}</div>
            <div className="card__body">
                <div className="text">
                    {texts[active].descriptions}
                </div>
                <div className="card__buttons">
                    <Button onClick={() => {
                        history.push("/menu?openAddress=1")
                    }} color={'green'} text={'оформить заказ'}/>
                    <Button onClick={() => {
                        history.push("/restaurants")
                    }} color={'white'} text={'рестораны'}/>
                </div>
            </div>
        </div>
    </div>
};

const MainBannerImage = () => {
    return <div className="main-banner__image"/>
};