import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/content`;

export class Content extends Base {
    getRestaurants() {
        return this.fetchJSON(`${BASE_URL}/restaurants`).then(response => {
            return response.data
        })
    }

    getRestaurant(slug) {
        return this.fetchJSON(`${BASE_URL}/restaurant/${slug}`).then(response => {
            return response.data
        })
    }

    getVacancies() {
        return this.fetchJSON(`${BASE_URL}/vacancies`).then(response => {
            return response.data
        })
    }

    getSeoData(location) {
        return this.fetchJSON(`${BASE_URL}/seo?url=${location}`).then(response => {
            return response.data
        })
    }
}