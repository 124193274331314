import {useContext} from 'react'

import A1 from "../../static/images/action/A1.webp"
import A2 from "../../static/images/action/A2.png"
import {STATIC_HOST} from "../../const";
import {AppContext} from "../../store/context";

export const ActionList = ({actions}) => {
    const {context} = useContext(AppContext);

    actions = actions || [];
    actions = actions.slice(0, 3);

    const actionOne = actions[0] || null;
    const actionTwo = actions[1] || null;
    const actionThree = actions[2] || null;

    const open = (el) => {
        context.modals.action.dispatch({type: 'select', payload: el});
        context.modals.action.dispatch({type: 'open'});
    };

    const Desktop = () => {
        return <div className="actions-holder">
            <div className="actions-row">
                {actionOne ?
                    <div className="action-card pointer" onClick={() => {
                        open(actionOne)
                    }}>
                        <div className="action-card-title">
                            <div className="action-card-title--discount">{actionOne.preview.one}</div>
                            <div className="action-card-title--text">{actionOne.preview.two}</div>
                            <div className="action-card-title--link" >подробнее
                            </div>
                        </div>
                        <div className="action-card-image right"
                             style={{backgroundImage: `url('${actionOne.image ? STATIC_HOST + actionOne.image.source : ''}')`}}/>
                    </div> : <div className="action-card">
                        <div className="action-card-image" style={{backgroundImage: `url('${A2}')`}}/>
                    </div>}
                {actionTwo ?
                    <div className="action-card pointer" onClick={() => {
                        open(actionTwo)
                    }}>
                        <div className="action-card-image left"
                             style={{backgroundImage: `url('${actionTwo.image ? STATIC_HOST + actionTwo.image.source : ''}')`}}/>
                        <div className="action-card-title">
                            <div className="action-card-title--discount">{actionTwo.preview.one}</div>
                            <div className="action-card-title--text">{actionTwo.preview.two}</div>
                            <div className="action-card-title--link" >подробнее
                            </div>
                        </div>
                    </div> : <div className="action-card">
                        <div className="action-card-image" style={{backgroundImage: `url('${A1}')`}}/>
                    </div>}
                <div className="action-card">
                    <div className="action-card-image" style={{backgroundImage: `url('${A2}')`}}/>
                </div>
            </div>
            <div className="actions-row">
                <div className="action-card">
                    <div className="action-card-image" style={{backgroundImage: `url('${A1}')`}}/>
                </div>
                {actionThree ?
                    <div className="action-card pointer" onClick={() => {
                        open(actionThree)
                    }}>
                        <div className="action-card-image left"
                             style={{backgroundImage: `url('${actionThree.image ? STATIC_HOST + actionThree.image.source : ''}')`}}/>
                        <div className="action-card-title">
                            <div className="action-card-title--discount">{actionThree.preview.one}</div>
                            <div className="action-card-title--text">{actionThree.preview.two}</div>
                            <div className="action-card-title--link" >подробнее
                            </div>
                        </div>
                    </div> : <div className="action-card">
                        <div className="action-card-image" style={{backgroundImage: `url('${A2}')`}}/>
                    </div>}
            </div>
        </div>
    }

    const Mobile = () => {
        return <div className="actions-holder">
            {actionOne ?
                <div className="action-card" onClick={() => {
                    open(actionOne)
                }}>
                    <div className="action-card-title">
                        <div className="action-card-title--discount">{actionOne.preview.one}</div>
                        <div className="action-card-title--text">{actionOne.preview.two}</div>
                        <div className="action-card-title--link">подробнее
                        </div>
                    </div>
                    <div className="action-card-image right"
                         style={{backgroundImage: `url('${actionOne.image ? STATIC_HOST + actionOne.image.source : ''}')`}}/>
                </div> : null}
            {actionTwo ?
                <div className="action-card" onClick={() => {
                    open(actionTwo)
                }}>
                    <div className="action-card-image left"
                         style={{backgroundImage: `url('${actionTwo.image ? STATIC_HOST + actionTwo.image.source : ''}')`}}/>
                    <div className="action-card-title">
                        <div className="action-card-title--discount">{actionTwo.preview.one}</div>
                        <div className="action-card-title--text">{actionTwo.preview.two}</div>
                        <div className="action-card-title--link">подробнее</div>
                    </div>
                </div> : null}
            {actionThree ?
                <div className="action-card" onClick={() => {
                    open(actionThree)
                }}>
                    <div className="action-card-image left"
                         style={{backgroundImage: `url('${actionThree.image ? STATIC_HOST + actionThree.image.source : ''}')`}}/>
                    <div className="action-card-title">
                        <div className="action-card-title--discount">{actionThree.preview.one}</div>
                        <div className="action-card-title--text">{actionThree.preview.two}</div>
                        <div className="action-card-title--link">подробнее
                        </div>
                    </div>
                </div> : null}
        </div>
    }

    return context.settings.isMobile
        ? <Mobile/>
        : <Desktop/>
};