import {Screen} from "./screen.api";
import {Content} from "./content.api";
import {Catalog} from "./catalog.api";
import {Cart} from "./cart.api";
import {Order} from "./order.api";
import {User} from "./user.api";
import {Forms} from "./forms.api";

export const Processor = {
    screen: new Screen(),
    content: new Content(),
    catalog: new Catalog(),
    cart: new Cart(),
    order: new Order(),
    user: new User(),
    forms: new Forms()
};