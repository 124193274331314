import {useEffect} from 'react'
import {useLocation} from 'react-router-dom';
import Routes from './routing/routes'
import {AppContext, AppContextValue} from "./store/context";
import ym, {YMInitializer} from 'react-yandex-metrika'

export default function App() {
    const context = AppContextValue();

    useEffect(() => {
        context.processor.content.getRestaurants().then(res => {
            context.restaurants.dispatch({type: 'setRestaurants', payload: res.restaurants});
        });

        context.processor.cart.loadCart().then(res => {
            context.cart.dispatch({type: 'setCart', payload: res.cart});
        });

        context.processor.catalog.categories().then(res => {
            context.catalog.dispatch({type: 'setCategories', payload: res.categories});
        });

        context.processor.catalog.recommendations().then(res => {
            context.catalog.dispatch({type: 'setRecommendations', payload: res.recommendations});
        });

        context.processor.user.loadUser().then(res => {
            context.user.dispatch({type: 'setUser', payload: res.user});
        }).catch(err => {
        })


        const isNewYear = () => {
            const date = new Date();
            const start = new Date('2022-12-27');
            const end = new Date('2023-01-02');
            return date > start && date < end
        }

        if (isNewYear()) {
            if (document.cookie.indexOf('new-year-closed=') !== -1) {

            } else {
                context.modals.newYear.dispatch({type: "open"})
                setCookie("new-year-closed", {'max-age': 60 * 60 * 12})
            }
        }
    }, []);

    return <AppContext.Provider value={{context}}>
        <YMInitializer accounts={[88105291]} options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        }}/>
        <Routes/>
    </AppContext.Provider>
}

export const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
        ym('hit', location.pathname);
    }, [location]);
};

function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}
