import React, {useContext, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom'

import {AppContext} from "../store/context";
import {SearchHeaderInput} from '../components/Form'

import logo from '../static/logo.svg'
import logo_mob from '../static/logo_mob.svg'
import cart from '../static/icons/cart.svg'
import search from '../static/icons/search_white.svg'
import user from '../static/icons/user.svg'
import phone from '../static/icons/phone.svg'
import arrow from '../static/icons/arrow_bold.svg'
import menu_toggler from '../static/icons/menu-toggler.svg'
import banner_text from '../static/images/banner/text.svg'

import cross from '../static/icons/cross.svg'

const BackButton = () => {
    const history = useHistory();

    return <div className={'back'} onClick={() => history.goBack()}>
        <div className="back__icon">
            <img src={arrow} alt=""/>
        </div>
        <div className="back__text">
            Назад
        </div>
    </div>
};


const DesktopNavBar = () => {
    const {context} = useContext(AppContext);
    const count = context.cart.state.count;

    const history = useHistory();
    const {pathname} = useLocation();

    const [searchOpen, setSearchOpen] = useState(false);
    const toggleSearchModal = () => {
        setSearchOpen(!searchOpen)
    };

    const toggleCartModal = pathname !== '/cart' && context.cart.state.sum !== 0
        ? () => {
            context.cart.dispatch({type: 'toggleModal'})
        }
        : () => {
        };

    const toggleAuthModal = pathname !== '/personal'
        ? context.user.state.isAuth
            ? () => {
                history.push('/personal')
            }
            : () => {
                context.modals.auth.dispatch({type: 'toggle'})
            }
        : () => {
            context.modals.auth.dispatch({type: 'toggleExit'})
        };

    return (
        <div className="nav">
            <div className="nav-logo" onClick={() => {
                history.push("/")
            }}><img src={logo} alt=""/></div>
            <div className="nav-inner">
                <div className="nav-menu">
                    {/*<Link to={'/'} className="nav-menu__item">главная</Link>*/}
                    <Link to={'/menu'} className="nav-menu__item">меню</Link>
                    <Link to={'/restaurants'} className="nav-menu__item">рестораны</Link>
                    <Link to={'/contacts'} className="nav-menu__item">контакты</Link>
                    {/*<Link to={'/about'} className="nav-menu__item">о нас</Link>*/}
                    <Link to={'/vacancies'} className="nav-menu__item">вакансии</Link>
                    <Link to={'/sluzhba-kontrolja-kachestva-zabotlivyj-servis'} className="nav-menu__item">заботливый сервис</Link>
                </div>
                <div className="nav-phone">
                    <a href="tel:+78124907464">
                        <span className={'nav-phone-icon'}>
                            <img src={phone} alt=""/>
                        </span>
                        490-74-64</a>
                </div>
                <div className="nav-buttons">
                    <div className="nav-buttons__item search-icon">
                        <SearchHeaderInput open={searchOpen} />
                        <img onClick={() => {
                            toggleSearchModal()
                        }} src={search} alt=""/></div>
                    <div onClick={() => {
                        toggleAuthModal()
                    }} className="nav-buttons__item"><img src={user} alt=""/></div>
                    <div onClick={() => {
                        toggleCartModal()
                    }} className="nav-buttons__item cart-counter">
                        <img src={cart} alt=""/>
                        <span className={`cart-counter--icon ${count > 0 ? 'active' : ''}`}>
                            <span className={'cart-counter--count'}>{count}</span>
                        </span>
                    </div>
                </div>
                <img src={banner_text} alt="" className="static-images banner_text"/>
            </div>
        </div>
    );
}

const MobileNavBar = () => {
    const {context} = useContext(AppContext);
    const count = context.cart.state.count;

    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const history = useHistory();
    const {pathname} = useLocation();

    const toggleCartModal = pathname !== '/cart' && context.cart.state.sum !== 0
        ? () => {
            context.cart.dispatch({type: 'toggleModal'})
        }
        : () => {
        };

    const toggleSearchModal = () => {
        setSearchOpen(!searchOpen)
    };

    const toggleAuthModal = pathname !== '/personal'
        ? context.user.state.isAuth
            ? () => {
                history.push('/personal')
            }
            : () => {
                context.modals.auth.dispatch({type: 'toggle'})
            }
        : () => {
            context.modals.auth.dispatch({type: 'toggleExit'})
        };

    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        <div className="nav">
            <div className="nav-logo" onClick={() => {
                history.push('/')
            }}><img src={logo_mob} alt=""/></div>
            <div className="nav-row">
                <div className="nav-phone">
                    <a href="tel:+78124907464">
                        <span className={'nav-phone-icon'}>
                            <img src={phone} alt=""/>
                        </span>
                        490-74-64</a>
                </div>
                <div onClick={() => {
                    toggleMenu()
                }} className="nav-menu-toggler"><img src={menu_toggler} alt=""/></div>
            </div>
            <div className="nav-buttons">
                <div className="nav-buttons__item search-icon">
                    <SearchHeaderInput open={searchOpen} fn={() => toggleSearchModal()}/>
                    <img onClick={() => {
                        toggleSearchModal()
                    }} src={search} alt=""/></div>
                <div onClick={() => {
                    toggleAuthModal()
                }} className="nav-buttons__item"><img src={user} alt=""/></div>
                <div onClick={() => {
                    toggleCartModal()
                }} className="nav-buttons__item cart-counter">
                    <img src={cart} alt=""/>
                    <span className={`cart-counter--icon ${count > 0 ? 'active' : ''}`}>
                            <span className={'cart-counter--count'}>{count}</span>
                        </span>
                </div>
            </div>
            <div className={`nav-inner ${open ? 'show' : ''}`}>
                <div className="nav-close" onClick={() => {
                    toggleMenu()
                }}>
                    <img src={cross} alt=""/>
                </div>
                <div className="nav-menu">
                    {/*<Link to={'/'} className="nav-menu__item">Главная</Link>*/}
                    <Link to={'/menu'} className="nav-menu__item">Меню</Link>
                    <Link to={'/restaurants'} className="nav-menu__item">Рестораны</Link>
                    <Link to={'/contacts'} className="nav-menu__item">Контакты</Link>
                    {/*<Link to={'/about'} className="nav-menu__item">О нас</Link>*/}
                    <Link to={'/vacancies'} className="nav-menu__item">Вакансии</Link>
                    <Link to={'/sluzhba-kontrolja-kachestva-zabotlivyj-servis'} className="nav-menu__item">Заботливый сервис</Link>
                </div>
            </div>
            {!pathname.includes('/order/success') && pathname !== '/' ? <BackButton/> : null}
        </div>
    );
};

const NavBar = () => {
    const {context} = useContext(AppContext);

    return context.settings.isMobile === false
        ? <DesktopNavBar/>
        : <MobileNavBar/>
}

export default NavBar
