import React from 'react'
import {Link} from 'react-router-dom'

import page404 from '../static/images/404.png'
import page404_mob from '../static/images/404_mob.png'

import {AppContext} from "../store/context";


export class Page404 extends React.Component {
    constructor() {
        super()
    }

    render() {
        return <div className={'not-found'}>
            <div className="not-found__image">
                <img src={this.context.context.settings.isMobile ? page404_mob : page404} alt=""/>
            </div>
            <div className="not-found__text">УПС! ЧТО-ТО ПОШЛО НЕ ТАК...</div>
            <div className="not-found__button">
                <Link to={'/'} className={'button button-green'}>
                    <span>Главная</span>
                </Link>
            </div>
        </div>
    }
}

Page404.contextType = AppContext;