import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import {BottomSeo} from '../components/Seo'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import {
    ModalHolder,
    ResponsiveActionModal,
    ResponsiveAuthModal,
    ResponsiveCartModal,
    ResponsivePickupModal,
    ResponsiveProductModal,
    ResponsiveVacancyModal
} from "../components/Modal";
import {AppContext} from "../store/context";
import {CartAnimation} from "../components/CartAnimation";
import {usePageViews} from "../App";

import arrow from '../static/icons/arrow_bold.svg'
import 'react-toastify/dist/ReactToastify.css';
import {ResponsiveNewYearModal} from "../components/NewYear";

const Main = ({className, children}) => {
    return <div className={className}>{children}</div>
};

const Clearfix = () => {
    return <div className={'clearfix'}/>
};

const BackButton = () => {
    const history = useHistory();

    return <div className={'container container-pd back'} onClick={() => history.goBack()}>
        <div className="back__icon">
            <img src={arrow} alt=""/>
        </div>
        <div className="back__text">
            Назад
        </div>
    </div>
};

const MainLayout = ({children}) => {
    const {pathname} = useLocation();
    const {context} = useContext(AppContext);

    usePageViews();

    return (
        <React.Fragment>
            <Main
                className={`page ${pathname.includes('/success') ? 'page-success' : ''} ${pathname.includes('/moscow') ? 'page-landing-rest' : ''} ${pathname.includes('/sochi') ? 'page-landing-rest' : ''} ${pathname.includes('/restaurants') ? 'page-restaurants' : ''} ${pathname.includes('/vacancies') ? 'page-vacancies' : ''}`}>
                <CartAnimation/>
                <NavBar/>
                {!pathname.includes('/order/success') && !context.settings.isMobile ? <BackButton/> : null}
                {!pathname.includes('/personal') && !pathname.includes('/restaurants') ? <Clearfix/> : null}
                {children}
                <Clearfix/>
                <BottomSeo/>
                <Footer/>
                <ModalHolder>
                    <ResponsivePickupModal/>
                    <ResponsiveNewYearModal/>
                    <ResponsiveProductModal/>
                    <ResponsiveAuthModal/>
                    <ResponsiveActionModal/>
                    <ResponsiveVacancyModal/>
                </ModalHolder>
                <ResponsiveCartModal/>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Main>
        </React.Fragment>
    );
};

export default MainLayout;
