import React from "react";
import Skeleton from 'react-loading-skeleton'
import {Link} from 'react-router-dom'

import {AppContext} from "../store/context";
import {TitleWGap} from "../components/Typo";

import pickle from '../static/images/success/pickle.png'
import onion from '../static/images/success/onion.png'
import petr1 from '../static/images/success/petr1.png'
import petr2 from '../static/images/success/petr2.png'


export class OrderSuccess extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            name: '',
            number: '',
            delivery: {
                type: 'pickup',
                pickupType: '',
                restaurant: {}
            }
        }
    }

    hashFromProps(props) {
        return props.match.params.hasOwnProperty('hash')
            ? props.match.params.hash
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const hash = this.hashFromProps(this.props);

        if (!hash) {
            ///TODO REDIRECT
        }

        this.context.context.processor.order.get(hash).then((res) => {
            this.setState({
                loaded: true,
                name: res.name,
                number: res.number,
                delivery: {
                    type: res.delivery.type,
                    pickupType: res.delivery.pickupType,
                    restaurant: res.delivery.restaurant
                }
            })
        }).catch(err => {
            //TODO REDIRECT
        })
    }

    render() {
        return <div className='order-success container container-pd'>
            <img src={onion} alt="" className="order-success-static--onion"/>
            <img src={petr1} alt="" className="order-success-static--petr1"/>
            <img src={petr2} alt="" className="order-success-static--petr2"/>
            <img src={pickle} alt="" className="order-success-static--pickle"/>
            <div className="order-success__title">
                <TitleWGap black={'cпасибо'} green={'за заказ!'}/>
            </div>
            <div className="order-success__info">
                <div className="order-success__info-greeting">
                    <div className={'text'}>{this.state.loaded ? `Здравствуйте, ${this.state.name}!` :
                        <Skeleton/>}</div>
                    <div className={'text'}>{this.state.loaded ? `Ваш заказ №${this.state.number} успешно оформлен` :
                        <Skeleton/>}</div>
                </div>
                <div className="order-success__info-delivery">
                    <div className="order-success__info-delivery--title">{this.state.delivery.pickupType === 'takeaway' ? 'Заказ заберете с собой:' : 'Заказ будет готов в зале:'}</div>
                    <div className="order-success__info-delivery--description">
                        <div className="col">
                            {this.state.loaded
                                ? <div className={'text'}>{this.state.delivery.restaurant.title}</div>
                                : <Skeleton/>}
                            {this.state.loaded
                                ? <div className={'text'}>{this.state.delivery.restaurant.address}</div>
                                : <Skeleton/>}
                        </div>
                        <div className="col">
                            {this.state.loaded
                                ? <div className={'text'}>{this.state.delivery.restaurant.phone}</div>
                                : <Skeleton/>}
                            {this.state.loaded
                                ? <div className={'text'}>График работы: {this.state.delivery.restaurant.operating}</div>
                                : <Skeleton/>}
                        </div>
                        <div className="col">
                            {this.state.loaded
                                ? <a target="_blank" className={'text'} href={this.state.delivery.restaurant.googleMaps} rel="noreferrer">Открыть в Google Maps</a>
                                : <Skeleton/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-success__info-actions">
                <Link to={'/menu'} className={'button button-green'}><span>Все меню</span></Link>
                <Link to={'/personal'} className={'button button-black'}><span>История заказов</span></Link>
            </div>
        </div>
    }
}

OrderSuccess.contextType = AppContext;
