import {useContext} from 'react'
import useInView from "react-cool-inview";

import {AppContext} from "../../store/context";

import arrow_1 from '../../static/images/rules/arrow_rule_1.svg'
import arrow_2 from '../../static/images/rules/arrow_rule_2.svg'
import arrow_3 from '../../static/images/rules/arrow_rule_3.svg'
import arrow_4 from '../../static/images/rules/arrow_rule_4.svg'
import arrow_5 from '../../static/images/rules/arrow_rule_5.svg'

import pibimpab from '../../static/images/rules/pibimpab.png'
import sause from '../../static/images/rules/sause.png'
import sticks from '../../static/images/rules/sticks.png'


export const FiveRulesBanner = () => {
    const {context} = useContext(AppContext);

    return context.settings.isMobile
        ? <FiveRulesBannerMobile/>
        : <FiveRulesBannerDesktop/>
};

const FiveRulesStep = ({arrow, title, text, number, step}) => {
    return <div className={`five-rules__step five-rules__step--${step}`}>
        <div className="five-rules__step_inner reveal">
            <div className="five-rules__step_title text--handwritten"><span
                className={'color-green'}>{number}. </span>{title}</div>
            <div className="five-rules__step_text">{text}</div>
        </div>
        <div className="five-rules__step_arrow reveal">
            <img src={arrow} alt=""/>
        </div>
    </div>
};

const FiveRulesBannerDesktop = () => {
    const {observe, inView} = useInView({
        unobserveOnEnter: true,
        rootMargin: "-300px 0px",
    });

    return <div ref={observe} className={`five-rules-banner ${inView ? 'active' : ''}`}>
        <FiveRulesStep step={'one'} number={1} title={"Баланс"}
                       text={"Наше меню основано на правильном сочетании белков, жиров и углеводов"} arrow={arrow_1}/>
        <FiveRulesStep step={'two'} number={2} title={"Свежесть"}
                       text={"Готовим без консервантов, стабилизаторов и искусственных ароматизаторов"} arrow={arrow_2}/>
        <FiveRulesStep step={'three'} number={3} title={"Витамины"}
                       text={"Основа корейской кухни – растительные элементы, богатые витаминами и минералами"} arrow={arrow_3}/>
        <FiveRulesStep step={'four'} number={4} title={"Технология приготовления"}
                       text={"Минимальная температурная обработка и сохранение полезных свойств продуктов"} arrow={arrow_4}/>
        <FiveRulesStep step={'five'} number={5} title={"Вкус и польза"}
                       text={"Яркий вкус - главный принцип корейского полезного питания"} arrow={arrow_5}/>
        <div className="five-rules-image">
            <div className="five-rules-image__pibimpab reveal-circle">
                <img src={pibimpab} alt=""/>
            </div>
            <div className="five-rules-image__sticks reveal-from-left">
                <img src={sticks} alt=""/>
            </div>
            <div className="five-rules-image__sause reveal-from-right">
                <img src={sause} alt=""/>
            </div>
        </div>
    </div>
};

const FiveRulesBannerMobile = () => {
    return <div className="five-rules-banner active">
        <FiveRulesStepMobile number={1} title={"Баланс"} text={"Наше меню основано на правильном сочетании белков, жиров и углеводов"}/>
        <FiveRulesStepMobile number={2} title={"Свежесть"} text={"Готовим без консервантов, стабилизаторов и искусственных ароматизаторов"}/>
        <FiveRulesStepMobile number={3} title={"Витамины"} text={"Основа корейской кухни – растительные элементы, богатые витаминами и минералами"}/>
        <FiveRulesStepMobile number={4} title={"Технология приготовления"} text={"Минимальная температурная обработка и сохранение полезных свойств продуктов"}/>
        <FiveRulesStepMobile number={5} title={"Вкус и польза"} text={"Яркий вкус - главный принцип корейского полезного питания"}/>
    </div>
};

const FiveRulesStepMobile = ({arrow, title, text, number}) => {
    return <div className={`five-rules__step`}>
        <div className="five-rules__step_inner">
            <div className="five-rules__step_title text--handwritten"><span
                className={'color-green'}>{number}. </span>{title}</div>
            <div className="five-rules__step_text">{text}</div>
        </div>
    </div>
};