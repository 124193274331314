import React, {useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {BottomSheet} from 'react-spring-bottom-sheet'

import {AppContext} from "../store/context";

import phone from '../static/icons/phone.svg'
import arrow from '../static/icons/arrow_down.svg'

export class Personal extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            orders: [],
            addresses: [],
            zones: {}
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const zones = {};

        if (this.context.context.restaurants.state.items.length === 0) {
            this.context.context.processor.content.getRestaurants().then(res => {
                res.restaurants.forEach(el => {
                    zones[el.zone.id] = el.title
                })
            });
        } else {
            this.context.context.restaurants.state.items.forEach((el) => {
                zones[el.zone.id] = el.title
            });
        }

        this.setState({
            zones: zones
        })

        this.context.context.processor.screen.getScreen('personal').then(res => {
            this.context.context.user.dispatch({type: 'setUser', payload: res.user});
            this.setState({
                orders: res.orders
            })
        }).then(() => {
            this.setState({...this.state, loaded: true})
        }).catch(err => {
            this.props.history.push('/')
        })
    }

    exit() {
        this.context.context.processor.user.logout().then(() => {
            this.context.context.user.dispatch({type: 'exit'});
            this.props.history.push('/')
        });

    }

    render() {
        return <div className="personal-page container">
            <div onClick={() => {this.exit()}}
                 className={`personal__exit ${this.context.context.modals.auth.state.openExit ? 'show' : ''}`}>
                Выйти
            </div>
            <div className="personal__title">
                <div className="title">
                    {!this.state.loaded
                        ? <>
                            <span className="skeleton-title"><Skeleton/></span>
                            <span className="skeleton-title-name"><Skeleton/></span>
                        </> : <>
                            <span className="color-white">Здравствуйте,</span><br/>
                            <span className="color-white">{this.context.context.user.state.user.name}!</span>
                        </>}
                </div>
            </div>
            {this.context.context.settings.isMobile
                ? <PersonalMobile loaded={this.state.loaded}
                                  user={this.context.context.user.state.user}
                                  orders={this.state.orders}
                                  zones={this.state.zones}
                                  addresses={this.state.addresses}/>
                : <PersonalDesktop loaded={this.state.loaded}
                                   user={this.context.context.user.state.user}
                                   orders={this.state.orders}
                                   zones={this.state.zones}
                                   addresses={this.state.addresses}/>}
        </div>
    }
}

const PersonalCardSupport = () => {
    return <>
        <div className="personal-card__title">
            <span>Поддержка</span>
        </div>
        <div className="personal-card__body">
            <div className="text">
                Дайте нам знать, если у вас возникли проблемы с сервисом
            </div>
            <a href={'tel:+78124907464'} className={'button button-green'}>
                <img src={phone} alt=""/>
            </a>
        </div>
    </>
}

const PersonalCardPersonal = ({loaded, user}) => {
    return <>
        <div className="personal-card__title">
            <span>Личные данные</span>
        </div>
        <div className="personal-card__body">
            <div className="personal__data-input">
                <div className="personal__data-input--label">Имя</div>
                <div className="personal__data-input--value">
                    {loaded ? user.name : <Skeleton/>}
                </div>
                <div className="personal__data-input--action"/>
            </div>
            <div className="personal__data-input">
                <div className="personal__data-input--label">Телефон</div>
                <div className="personal__data-input--value">
                    {loaded ? user.phone : <Skeleton/>}
                </div>
                <div className="personal__data-input--action"/>
            </div>

            {/*<div className="personal__data-input">*/}
                {/*<div className="personal__data-input--label">Email</div>*/}
                {/*<div className="personal__data-input--value">*/}
                    {/*{loaded ? user.email : <Skeleton/>}*/}
                {/*</div>*/}
                {/*<div className="personal__data-input--action"/>*/}
            {/*</div>*/}
        </div>
    </>
};

const PersonalCardAddresses = ({loaded, addresses}) => {
    return <>
        <div className="personal-card__title">
            <span>Управление адресами</span>
        </div>
        <div className="personal-card__body">
            {addresses.length ? addresses.map(el => {
                return <div className="personal__address"/>
            }) : <div className="personal-card__body--alert">У вас пока нет сохраненных адресов</div>}
        </div>
    </>
};

const PersonalCardOrders = ({loaded, orders, zones}) => {
    return <>
        <div className="personal-card personal-card--orders">
            <div className="personal-card__title">
                <span>Мои заказы</span>
            </div>
            <div className="personal-card__body">
                {loaded
                    ? orders.length
                        ? orders.map(el => {
                            return <PersonalOrder skeleton={false} order={el} zones={zones}/>
                        })
                        : <div className="personal-card__body--alert">У вас пока нет заказов</div>
                    : <PersonalOrder skeleton={true} order={OrderSkeleton} zones={{}}/>}
            </div>
        </div>
    </>
}

const PersonalDesktop = ({loaded, user, orders, addresses, zones}) => {
    return <>
        <div className="personal__info">
            <div className="personal-card personal-card--support">
                <PersonalCardSupport/>
            </div>
            <div className="personal-card personal-card--data">
                <PersonalCardPersonal loaded={loaded} user={user}/>
            </div>
            <div className="personal-card personal-card--addresses">
                <PersonalCardAddresses loaded={loaded} addresses={addresses}/>
            </div>
        </div>
        <div className="personal__orders">
            <PersonalCardOrders loaded={loaded} orders={orders} zones={zones}/>
        </div>
    </>
}

const PersonalMobile = ({loaded, user, orders, addresses, zones}) => {
    const [BSPersonal, setBSPersonal] = useState(false);
    const [BSOrders, setBSOrders] = useState(false);
    const [BSAddresses, setBSAddresses] = useState(false);
    const [BSSupport, setBSSupport] = useState(false);

    const onDismiss = () => {
        setBSPersonal(false);
        setBSOrders(false);
        setBSAddresses(false);
        setBSSupport(false);
    };

    const openBSPersonal = () => {
        setBSPersonal(true)
    };

    const openBSOrders = () => {
        setBSOrders(true)
    };

    const openBSAddresses = () => {
        setBSAddresses(true)
    };

    const openBSSupport = () => {
        setBSSupport(true)
    };

    return <div className="personal__bs-holder">
        <div className="personal__bs-button" onClick={() => openBSPersonal()}>
            <div className="personal__bs-button_title">Личные данные</div>
            <div className="personal__bs-button_icon"><img src={arrow} alt=""/></div>
        </div>
        <div className="personal__bs-button" onClick={() => openBSAddresses()}>
            <div className="personal__bs-button_title">Управление адресами</div>
            <div className="personal__bs-button_icon"><img src={arrow} alt=""/></div>
        </div>
        <div className="personal__bs-button" onClick={() => openBSOrders()}>
            <div className="personal__bs-button_title">Мои заказы</div>
            <div className="personal__bs-button_icon"><img src={arrow} alt=""/></div>
        </div>
        <div className="personal__bs-button personal__bs-button--green" onClick={() => openBSSupport()}>
            <div className="personal__bs-button_title">Поддержка</div>
            <div className="personal__bs-button_icon"><img src={arrow} alt=""/></div>
        </div>
        <BottomSheet
            header={<div className="modal-header-clearfix"></div>}
            onDismiss={onDismiss} open={BSPersonal} snapPoints={({maxHeight}) => maxHeight / 1.5}>
            <div className="personal-card__bs">
                <PersonalCardPersonal loaded={loaded} user={user}/>
            </div>
        </BottomSheet>
        <BottomSheet
            header={<div className="modal-header-clearfix"></div>}
            onDismiss={onDismiss} open={BSAddresses} snapPoints={({maxHeight}) => maxHeight / 1.5}>
            <div className="personal-card__bs">
                <PersonalCardAddresses loaded={loaded} addresses={addresses}/>
            </div>
        </BottomSheet>
        <BottomSheet
            header={<div className="modal-header-clearfix"></div>}
            onDismiss={onDismiss} open={BSOrders} snapPoints={({maxHeight}) => maxHeight / 1.5}>
            <div className="personal-card__bs">
                <PersonalCardOrders loaded={loaded} orders={orders} zones={zones}/>
            </div>
        </BottomSheet>
        <BottomSheet
            header={<div className="modal-header-clearfix"></div>}
            onDismiss={onDismiss} open={BSSupport} snapPoints={({maxHeight}) => maxHeight / 1.5}>
            <div className="personal-card__bs">
                <PersonalCardSupport/>
            </div>
        </BottomSheet>
    </div>
};

const OrderSkeleton = {
    id: <Skeleton/>,
    address: <Skeleton/>,
    created: <Skeleton/>,
    info: {pickup: true, deliveryPrice: 0, address: {city: <Skeleton/>, street: null}},
    payment: <Skeleton/>,
    products: [
        {name: <Skeleton/>, option: <Skeleton/>, count: <Skeleton/>, price: <Skeleton/>},
        {name: <Skeleton/>, option: <Skeleton/>, count: <Skeleton/>, price: <Skeleton/>},
        {name: <Skeleton/>, option: <Skeleton/>, count: <Skeleton/>, price: <Skeleton/>}],
    status: <Skeleton/>,
    zone: null,
};

const PersonalOrder = ({skeleton, order, zones}) => {
    skeleton = skeleton || false;
    let sum = 0;

    let paymentType = null;

    const paymentTypeMap = {
        'cash': 'Наличные',
        'card': 'Картой'
    }

    if (!skeleton) {
        order.products.forEach(el => {
            sum = sum + el.price * el.count
        });

        paymentType = paymentTypeMap[order.payment]

    } else {
        sum = <Skeleton/>
        paymentType = <Skeleton/>
    }



    return <div className="personal__order">
        <div className="personal__order-title">
            <div className="personal__order-number">
                <span><strong># {order.id}</strong> {zones[order.zone] ? `Ресторан ${zones[order.zone]}` : ''}</span>
                <span>{order.created}</span>
            </div>
            <div className="personal__order-status">{order.status || 'Заказ оформлен'}</div>
        </div>
        <div className="personal__order-products">
            {order.products.map(el => {
                return <div className="personal__order-product">
                    <div className="personal__order-product--col">{el.name}</div>
                    <div className="personal__order-product--col">{el.option}</div>
                    <div className="personal__order-product--col">{el.count} шт.</div>
                    <div className="personal__order-product--col">{el.price} ₽</div>
                </div>
            })}
        </div>
        <div className="personal__order-summary">
            <div className="personal__order-summary--title">Итого:</div>
            <div className="personal__order-summary--value">{sum} ₽</div>
        </div>
        <div className="personal__order-payment">
            <div className="personal__order-payment--title">Форма оплаты:</div>
            <div className="personal__order-payment--value">{paymentType}</div>
        </div>
        <div className="personal__order-bottom">
            <div/>
        </div>
    </div>
}

Personal.contextType = AppContext;
