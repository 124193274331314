import React from 'react'
import {AppContext} from "../store/context";
import {Checkout} from "../components/Checkout";

export class CheckoutPage extends React.Component {
    constructor() {
        super()
    }

    componentDidMount() {
        if (!this.context.context.settings.isMobile) {
            return this.props.history.push('/cart')
        }
    }

    render() {
        return <div className={'container page-checkout'}>
            <Checkout/>
        </div>
    }
}

CheckoutPage.contextType = AppContext;