import React from 'react'
import {AppContext} from "../../store/context";
import {RestaurantDropdown} from "../../components/Dropdown";

import {ADDITIONAL_RESTAURANTS} from '../../const';

import arrow from '../../static/images/landings/service/arrow.svg'
import team from '../../static/images/landings/service/team.png'
import man1 from '../../static/images/landings/service/man1.png'
import man2 from '../../static/images/landings/service/man2.png'
import man3 from '../../static/images/landings/service/man3.png'

import pibim from '../../static/images/landings/service/pibim.png'
import cherry from '../../static/images/landings/service/cherry.png'
import pibimMob from '../../static/images/landings/service/pibim-mob.png'
import cherryMob from '../../static/images/landings/service/cherry-mob.png'
import leafs1 from '../../static/images/landings/service/leafs1.png'
import leafs2 from '../../static/images/landings/service/leafs2.png'
import how from '../../static/images/landings/service/how.png'
import star from '../../static/images/landings/service/star.svg'
import starEmpty from '../../static/images/landings/service/star-empty.svg'

import radio_active from "../../static/icons/radio_active.svg";
import radio from "../../static/icons/radio.svg";

import styles from "./Service.module.css";

const config = {
    worst: {
        title: "Что вас разочаровало? Мы станем лучше, если расскажете в комментариях подробнее.",
        questions: [
            "вкус блюд/напитков совсем не понравился",
            "сотрудник был груб со мной",
            "в блюде был инородный предмет",
            "мне не понравилось качество продуктов",
            "в зале очень неуютно",
            "очень долго готовили заказ"
        ]
    },
    bad: {
        title: "Что вас разочаровало? Мы станем лучше, если расскажете в комментариях подробнее.",
        questions: [
            "вкус некоторых блюд/напитков не понравился",
            "сотрудник был неприветлив",
            "в зале неуютно",
            "долго готовили заказ",
            "заказ был неверно укомплектован",
            "не понравилась температура блюд",
            "маленький размер порций"
        ]
    },
    ok: {
        title: "Что испортило впечатление? Мы станем лучше, если расскажете в комментариях подробнее.",
        questions: [
            "вкус некоторых блюд/напитков не понравился",
            "сотрудник был неприветлив",
            "в зале неуютно",
            "долго готовили заказ",
            "заказ был неверно укомплектован",
            "не понравилась температура блюд",
            "маленький размер порций"
        ]
    },
    good: {
        title: "Что вам особенно понравилось?",
        questions: [
            "понравилась коммуникация с персоналом",
            "вкус и качество блюд на высоте",
            "в зале очень уютно",
            "быстро выдали заказ",
            "понравилась концепция",
            "широкий ассортимент"
        ],
    }
}

export class Service extends React.Component {
    constructor() {
        super();

        this.onSelect = this.onSelect.bind(this)
        this.ref = React.createRef()

        this.state = {
            proceed: false,
            progress: false,

            stars: 0,
            name: null,
            contact: null,
            choices: [],
            comment: null,
            selected: {
                id: 0,
                title: 'Выберите ресторан',
            },
            restaurants: [],
        }
    }

    restFromProps(props) {
        return props.match.params.hasOwnProperty('slug')
            ? props.match.params.slug
            : null
    }

    componentDidMount() {
        this.context.context.processor.content.getRestaurants().then(res => {
            this.setState({
                restaurants: res.restaurants.concat(ADDITIONAL_RESTAURANTS)
            })
        })

        const rest = this.restFromProps(this.props)

        if (rest !== null) {
            return;
        }

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    onSelect(el) {
        window.scrollTo({top: this.ref?.current?.offsetTop - 100, left: 0, behavior: "smooth"});
        this.setState({contact: null, choices: [], name: null, comment: null, selected: el, stars: 0})
    }

    send() {
        if (this.state.progress) return;

        this.setState({progress: true})

        if (this.state.stars < 1) {
            this.context.context.toast.error("Пожалуйста, оставьте оценку")
            this.setState({progress: false})
            return
        }

        if (this.state.choices.length < 1) {
            this.context.context.toast.error("Пожалуйста, выберите ответ из списка")
            this.setState({progress: false})
            return
        }

        if (this.state.selected.id < 1) {
            this.context.context.toast.error("Пожалуйста, выберите ресторан")
            this.setState({progress: false})
            return
        }

        const data = {
            stars: this.state.stars,
            name: this.state.name || 'не указано',
            contact: this.state.contact || 'не указано',
            comment: this.state.comment || 'не указано',
            choices: this.state.choices.join('; '),
            restaurant: this.state.selected.title
        }

        this.context.context.processor.forms
            .service(data)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
            .finally(() => this.setState({progress: false}))
    }

    _questions() {
        let cfg;

        switch (this.state.stars) {
            case 1:
            case 2:
                cfg = config.worst
                break;
            case 3:
                cfg = config.bad
                break;
            case 4:
                cfg = config.ok
                break;
            case 5:
                cfg = config.good
                break;
            default:
                return
        }


        return <>
            <div className={styles.questionsTitle}>{cfg.title}</div>
            <div className={styles.questionsItems}>
                {cfg.questions.map(el => {
                    return <div className={styles.question} onClick={() => this.toggleChoice(el)}>
                        <span><img src={this.containsChoice(el) ? radio_active : radio} alt=""/></span>
                        <span>{el}</span>
                    </div>
                })}
            </div>
        </>
    }

    toggleChoice(el) {
        const choices = this.state.choices

        const index = choices.indexOf(el);
        if (index > -1) { // only splice array when item is found
            choices.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            choices.push(el)
        }

        this.setState({choices: choices})
    }

    containsChoice(el) {
        return this.state.choices.indexOf(el) > -1;
    }

    render() {
        return (
            <div className="about-page reveal">
                <div className="container container-pd">
                    <div className={styles.intro}>
                        <div className={styles.introText}>
                            <h1 className={styles.title}>
                                <span className='color-black'>{"заботливый сервис в "}</span>
                                <span className='color-green'>{"кореана light"}</span>
                            </h1>
                            <div className={styles.titleArrow}>
                                <img src={arrow} alt=""/>
                            </div>
                            <div className={styles.subtitle}>
                                <span>- ЭТО ВНИМАНИЕ И ТЕПЛОЕ ОТНОШЕНИЕ К КАЖДОМУ ГОСТЮ!</span>
                            </div>
                        </div>
                        <div className={styles.introImage}>
                            <img src={team} alt=""/>
                        </div>
                    </div>
                    <div className={styles.introAfterText}>
                        Мы рады, что вы выбрали рестораны сети Koreana Light. Это очень важно для всей нашей
                        команды, в
                        которой каждый сотрудник старается обеспечить вкусное, приятное и комфортное посещение нашим
                        любимым гостям.
                    </div>
                </div>
                <div className={styles.elements}>
                    <h2 className={styles.elementsTitle}>
                        <span className='color-black'>{"заботливый сервис в "}</span>
                        <span className='color-green'>{"кореана light "}</span>
                        <span className='color-black'>{"включает:"}</span>
                    </h2>
                    <div className={styles.elementsGrid}>
                        <div className={classNames(styles.elementsCard, styles.elementsTile)}>
                            <div className={styles.elementsHead}>Персональный подход</div>
                            <div className={styles.elementsText}>
                                Индивидуальный подход к каждому гостю! Мы принимаем во внимание все обращения наших
                                гостей и даём персонализированную обратную связь, без ботов и автоматических
                                сообщений.
                            </div>
                        </div>
                        <div className={classNames(styles.elementsCard, styles.elementsTile)}>
                            <div className={styles.elementsHead}>Прозрачность</div>
                            <div className={styles.elementsText}>
                                Гарантируем абсолютную прозрачность: предоставляем нашим гостям всю интересующую их
                                информацию об услугах, продуктах и меню, обеспечиваем прозрачность условий и цен.
                            </div>
                        </div>
                        <div className={classNames(styles.elementsCard, styles.elementsTile)}>
                            <div className={styles.elementsHead}>Эффективное решение</div>
                            <div className={styles.elementsText}>
                                Находим выход из любой сложной ситуации: помогаем гостям успешно и быстро решить все
                                вопросы и пожелания, которые возникают в ресторанах Кореана Light.
                            </div>
                        </div>
                        <div className={styles.elementsTile}>
                            <img src={man1} alt=""/>
                        </div>
                        <div className={styles.elementsTile}>
                            <img src={man2} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.steps}>
                    <h2 className={styles.stepsTitle}>
                        <img src={leafs1} alt=""/>
                        <span className='color-black'>{"Мы стремимся быть "}</span>
                        <span className='color-green'>{"лучшим корейским стрит-фуд "}</span>
                        <span className='color-black'>{"рестораном в Санкт-Петербурге"}</span>
                    </h2>
                    <div className={styles.stepsInner}>
                        <div className={styles.stepsImage}>
                            <img src={this.context.context.settings.isMobile ? pibimMob : pibim} alt=""/>
                        </div>
                        <div className={styles.stepsGrid}>
                            <div className={styles.stepsCard}>
                                <div className={styles.stepsCardNum}>1.</div>
                                <div className={styles.stepsCardText}>
                                    Тщательно следим за сроками годности продуктов, ежедневно доставляем свежие
                                    продукты
                                    в наши рестораны и постоянно совершенствуем технологии приготовления блюд.
                                </div>
                            </div>
                            <div className={styles.stepsCard}>
                                <div className={styles.stepsCardNum}>2.</div>
                                <div className={styles.stepsCardText}>
                                    Каждый месяц наши рестораны проходят проверки от территориальных шеф-поваров и
                                    бренд-шефов.
                                </div>
                            </div>
                            <div className={styles.stepsCard}>
                                <div className={styles.stepsCardNum}>3.</div>
                                <div className={styles.stepsCardText}>
                                    Проводим множество профессиональных тренингов для наших поваров, кассиров и
                                    других
                                    сотрудников.
                                </div>
                            </div>
                        </div>
                        <img src={this.context.context.settings.isMobile ? cherryMob : cherry} alt=""/>
                    </div>
                </div>
                <div className={styles.how}>
                    <h2 className={styles.howTitle}>
                        <span className='color-black'>{"как работает "}</span>
                        <span className='color-green'>{"заботливый сервис"}</span>
                        <span className='color-black'>{"?"}</span>
                    </h2>
                    <div className={styles.howInner}>
                        <div className={styles.howBody}>
                            <div className={styles.howHead}>
                                Мы стараемся, чтобы наши гости всегда были довольны качеством наших блюд и
                                обслуживания!
                            </div>
                            <div className={styles.howText}>
                                Но не всегда всё получается идеально. И мы переживаем, когда допускаем ошибки.
                                Однако мы
                                точно знаем, что всё можно исправить. Специально для этого мы разработали программу
                                «Заботливый сервис».
                            </div>
                        </div>
                        <div className={styles.howImage}>
                            <img src={how} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={styles.disclamer}>
                    <div className={styles.disclamerImage}>
                        <img src={man3} alt=""/>
                    </div>
                    <div className={styles.disclamerBody}>
                        <div className={styles.disclamerHead}>
                            Мы непременно во всем разберемся и найдем решение каждого вопроса, связанного с работой
                            наших ресторанов.
                        </div>
                        <div className={styles.disclamerText}>
                            Если у вас возник какой-то вопрос или пожелание, пожалуйста, оставьте обращение в форме
                            ниже. Менеджеры контроля качества ресторанов Кореана Light обязательно изучат ваше
                            обращение и свяжутся с вами, чтобы уточнить информацию.
                        </div>
                    </div>
                    <img src={leafs2} alt=""/>
                </div>
                <div className={styles.form}>
                    {!this.state.proceed ? <div className={styles.formInner} ref={this.ref}>
                        <div className={styles.formTitle}>
                            <span className='color-black'>{"оставьте "}</span>
                            <span className='color-green'>{"оценку"}</span>
                        </div>
                        <div className={styles.formSelector}>
                            <RestaurantDropdown selected={this.state.selected}
                                                onSelect={this.onSelect}
                                                fromProps={this.restFromProps(this.props)}
                                                withAddress={true}
                                                customList={this.state.restaurants}
                                                />
                        </div>
                        {this.state.selected?.id !== 0 ? <div className={styles.stars}>
                            <div className={styles.star} onClick={() => this.setState({choices: [], stars: 1})}>
                                <img src={this.state.stars > 0 ? star : starEmpty} alt=""/>
                            </div>
                            <div className={styles.star} onClick={() => this.setState({choices: [], stars: 2})}>
                                <img src={this.state.stars > 1 ? star : starEmpty} alt=""/>
                            </div>
                            <div className={styles.star} onClick={() => this.setState({choices: [], stars: 3})}>
                                <img src={this.state.stars > 2 ? star : starEmpty} alt=""/>
                            </div>
                            <div className={styles.star} onClick={() => this.setState({choices: [], stars: 4})}>
                                <img src={this.state.stars > 3 ? star : starEmpty} alt=""/>
                            </div>
                            <div className={styles.star} onClick={() => this.setState({choices: [], stars: 5})}>
                                <img src={this.state.stars > 4 ? star : starEmpty} alt=""/>
                            </div>
                        </div> : null}
                        {this.state.stars > 0 ? <div className={styles.formQuestions}>
                            {this._questions()}
                        </div> : null}
                        {this.state.stars > 0 ?
                            <div className={styles.formInputs}>
                                {this.state.stars < 5 ? <div className={styles.formInputsRow}>
                                    <input
                                        value={this.state.name}
                                        type="text" placeholder={'Ваше имя'}
                                        onChange={e => this.setState({name: e.currentTarget.value})}/>
                                    <input
                                        value={this.state.contact}
                                        type="text" placeholder={'Телефон или email'}
                                        onChange={e => this.setState({contact: e.currentTarget.value})}/>
                                </div> : null}
                                <div className={styles.formInputsRow}>
                                    <input
                                        value={this.state.comment}
                                        type="text" placeholder={'Комментарий'}
                                        onChange={e => this.setState({comment: e.currentTarget.value})}/>
                                </div>
                                <div className={styles.button} onClick={() => this.send()}>
                                    отправить
                                </div>
                            </div> : null}
                    </div> : <div className={styles.formInner}>
                        <div className={styles.formTitle}>
                            <span className='color-black'>{"спасибо за "}</span>
                            <span className='color-green'>{"отзыв!"}</span>
                        </div>
                        <div className={styles.formText}>
                            Менеджеры контроля качества Кореана Light обязательно изучат
                            ваше обращение и свяжутся с вами, если необходимо уточнить информацию.
                        </div>
                    </div>}
                    <div className={styles.formImage}/>
                </div>
            </div>
        );
    }
}

Service.contextType = AppContext


export function classNames(...str) {
    return [...str,].join(" ")
}
