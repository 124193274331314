export const Button = ({color, text, onClick}) => {
    let buttonClass = '';

    switch (color) {
        case 'white':
            buttonClass = 'button-white';
            break;
        case 'green':
        default:
            buttonClass = 'button-green';
    }

    return (<div className={'button '  + buttonClass} onClick={onClick}>
            <span>{text}</span>
        </div>
    )
};
