import React, {createContext, useEffect, useReducer, useState} from "react";
import {Processor} from "../api/processor";
import {toast} from 'react-toastify'
import {
    ActionModalInitialState,
    ActionModalReducer,
    AuthModalInitialState,
    AuthModalReducer,
    CartInitialState,
    CartReducer,
    CatalogInitialState,
    CatalogReducer, CommonInitialState, CommonReducer,
    ProductModalInitialState,
    ProductModalReducer,
    RestaurantInitialState,
    RestaurantModalInitialState,
    RestaurantModalReducer,
    RestaurantReducer,
    UserInitialState,
    UserReducer,
    VacancyModalInitialState,
    VacancyModalReducer,
    NewYearModalReducer,
    NewYearModalInitialState,
} from './reducers'


export const AppContext = createContext();
export const AppContextValue = () => {
    const [cartState, cartDispatch] = useReducer(CartReducer, CartInitialState);
    const [productModalState, productModalDispatch] = useReducer(ProductModalReducer, ProductModalInitialState);
    const [authModalState, authModalDispatch] = useReducer(AuthModalReducer, AuthModalInitialState);
    const [restaurantModalState, restaurantModalDispatch] = useReducer(RestaurantModalReducer, RestaurantModalInitialState);
    const [restaurantsState, restaurantsDispatch] = useReducer(RestaurantReducer, RestaurantInitialState);
    const [catalogState, catalogDispatch] = useReducer(CatalogReducer, CatalogInitialState);
    const [userState, userDispatch] = useReducer(UserReducer, UserInitialState);
    const [commonState, commonDispatch] = useReducer(CommonReducer, CommonInitialState);
    const [actionState, actionDispatch] = useReducer(ActionModalReducer, ActionModalInitialState);
    const [vacancyState, vacancyDispatch] = useReducer(VacancyModalReducer, VacancyModalInitialState);
    const [newYearState, newYearDispatch] = useReducer(NewYearModalReducer, NewYearModalInitialState);
    const isMobile = useCheckMobileScreen();

    return {
        settings: {
            isMobile: isMobile
        },
        common: {
            state: commonState,
            dispatch: commonDispatch
        },
        user: {
            state: userState,
            dispatch: userDispatch
        },
        cart: {
            state: cartState,
            dispatch: cartDispatch
        },
        catalog: {
            state: catalogState,
            dispatch: catalogDispatch,
        },
        restaurants: {
            state: restaurantsState,
            dispatch: restaurantsDispatch
        },
        modals: {
            restaurant: {
                state: restaurantModalState,
                dispatch: restaurantModalDispatch
            },
            product: {
                state: productModalState,
                dispatch: productModalDispatch
            },
            auth: {
                state: authModalState,
                dispatch: authModalDispatch
            },
            action: {
                state: actionState,
                dispatch: actionDispatch
            },
            vacancy: {
                state: vacancyState,
                dispatch: vacancyDispatch
            },
            newYear: {
                state: newYearState,
                dispatch: newYearDispatch
            },
        },
        toast: toast,
        processor: Processor
    }
};

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
};
