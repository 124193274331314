import vegan from './static/icons/vegan.svg'
import hit from './static/icons/star.svg'
import spicy from './static/icons/hot.svg'

export const API_HOST = process.env.REACT_APP_API_HOST;
export const STATIC_HOST = process.env.REACT_APP_STATIC_HOST;

export const DEFAULT_TITLE = 'Корейское стрит фуд кафе Koreana Light в Санкт-Петербурге | koreanalight.ru'
export const DEFAULT_DESCRIPTION = 'Стрит фуд кафе Koreana Light в Санкт-Петербурге со свежей и сбалансированной корейской кухней, доступной каждому.  Акции. Предзаказ. Самовывоз. Телефон для заказа: 490-74-64'
export const CATALOG_FILTERS = [
    {
        type: 'spicy',
        title: 'Острое',
        icon: spicy,
    },
    {
        type: 'hit-list',
        title: 'Хит',
        icon: hit,
    },
    {
        type: 'vegan',
        title: 'Вегетарианское',
        icon: vegan,
    },
];

export const CATALOG_SORT_DEFAULT = {
    title: 'Сортировка',
    type: 'default',
    value: 'Сортировка',
    direction: null
};

export const CATALOG_SORT = [
    {
        type: 'price_asc',
        title: 'Цена: по возрастанию',
        value: 'Цена',
        direction: 'asc'
    },
    {
        type: 'price_desc',
        title: 'Цена: по убыванию',
        value: 'Цена',
        direction: 'desc'
    },
    {
        type: 'popularity_asc',
        title: 'Сначала популярные',
        value: 'Популярность',
        direction: 'asc'
    },
];

export const ADDITIONAL_RESTAURANTS = [
    {
        "id": 100,
        "title": "ТРК \"Европолис\" Ростокино (Москва)",
        "slug": "evropolis",
        "images": [],
        "phone": "+7 (812) 490-74-64",
        "description": "",
        "active": true,
        "operating": "\u0415\u0436\u0435\u0434\u043d\u0435\u0432\u043d\u043e: 10:00 - 22:00",
        "address": "Москва, пр-т Мира 211",
        "googleMaps": "https://goo.gl/maps/udMpgn8GxvjpL52S7",
        "latitude": 0.0,
        "longitude": 0.0,
        "zone": {
            "id": 1,
            "title": "\u041d\u0435\u0432\u0441\u043a\u0438\u0439",
            "freePrice": 0,
            "deliveryTime": "\u0412 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 \u0447\u0430\u0441\u0430",
            "price": 0,
            "cardOnly": false,
            "active": true
        }
    },
    {
        "id": 101,
        "title": "ТРК \"МореМолл\" (Сочи)",
        "slug": "moremall",
        "images": [],
        "phone": "+7 965 484 42 73",
        "description": "",
        "active": true,
        "operating": "\u0415\u0436\u0435\u0434\u043d\u0435\u0432\u043d\u043e: 10:00 - 22:00",
        "address": "Сочи, ул.Новая Заря 7, микрорайон Донская",
        "googleMaps": "https://goo.gl/maps/N4KfSFspgDiMSwVY8",
        "latitude": 0.0,
        "longitude": 0.0,
        "zone": {
            "id": 1,
            "title": "\u041d\u0435\u0432\u0441\u043a\u0438\u0439",
            "freePrice": 0,
            "deliveryTime": "\u0412 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 \u0447\u0430\u0441\u0430",
            "price": 0,
            "cardOnly": false,
            "active": true
        }
    },
    {
        "id": 102,
        "title": "Краснодар",
        "slug": "krasnodar",
        "images": [],
        "phone": "+7 (812) 490-74-64",
        "description": "",
        "active": true,
        "operating": "\u0415\u0436\u0435\u0434\u043d\u0435\u0432\u043d\u043e: 10:00 - 22:00",
        "address": "Красная улица, 176/5с1",
        "googleMaps": "https://maps.app.goo.gl/f3ZNQVFPhET6pYF69",
        "latitude": 0.0,
        "longitude": 0.0,
        "zone": {
            "id": 1,
            "title": "\u041d\u0435\u0432\u0441\u043a\u0438\u0439",
            "freePrice": 0,
            "deliveryTime": "\u0412 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 \u0447\u0430\u0441\u0430",
            "price": 0,
            "cardOnly": false,
            "active": true
        }
    }
]

export function SortMap(sort) {
    if (!sort) {
        sort = {
            type: 'popularity_asc'
        }
    }

    switch (sort.type) {
        case 'price_asc':
            return (a, b) => {
                if (a.price > b.price) {
                    return 1;
                }
                if (a.price < b.price) {
                    return -1;
                }

                return 0;
            };
        case 'price_desc':
            return (a, b) => {
                if (a.price < b.price) {
                    return 1;
                }
                if (a.price > b.price) {
                    return -1;
                }

                return 0;
            };
        case 'spice_asc':
            return (a, b) => {
                if (a.price > b.price) {
                    return 1;
                }
                if (a.price < b.price) {
                    return -1;
                }

                return 0;
            };
        case 'spice_desc':
            return (a, b) => {
                if (a.price < b.price) {
                    return 1;
                }
                if (a.price > b.price) {
                    return -1;
                }

                return 0;
            };
        case 'popularity_asc':
            return (a, b) => {
                if (a.popularity > b.popularity) {
                    return 1;
                }
                if (a.popularity < b.popularity) {
                    return -1;
                }

                return 0;
            };
        default:
            return () => {
                return 0
            }
    }
}
