import React, {useContext} from 'react'
import {AppContext} from "../store/context";
import {Accordeon} from '../components/Accordeon'
import {Button} from "../components/Buttons";
import {Loader} from "../components/Loader";
import {VacanciesForm} from "../components/Form"

import arrow from '../static/images/vacancies/arrow.png'
import shpinat1 from '../static/images/vacancies/shpinat1.png'
import shpinat2 from '../static/images/vacancies/shpinat2.png'

export class Vacancies extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: true,
            vacancies: [],
        }
    }

    componentDidMount() {
        this.context.context.processor.content.getVacancies().then(res => {
            this.setState({
                loaded: true,
                vacancies: res.vacancies
            })
        })
    }

    render() {
        return <div className="vacancies-page reveal">
            <div className="container container-pd">
                <div className="vacancies-page__description">
                    <div className="title">
                        <span className='color-black'>Вакансии</span><br/>
                    </div>
                    <div className="text">
                        Мы черпаем вдохновение в людях, которые нас окружают и с любовью относимся к нашим сотрудникам. Станьте частью нашей команды Koreana Light!
                    </div>
                </div>
            </div>
            {this.state.loaded && this.state.vacancies.length ?
                <div className="container vacancies-list accordeon">
                    {!this.context.context.settings.isMobile ?
                        <div className="vacancies-list__arrow reveal-from-right">
                            <img src={arrow} alt=""/>
                            <div className="text--handwritten">Открыта Вакансия!</div>
                        </div> : <VacancyAdvantages/>}
                    {this.state.vacancies.map(el => {
                        return <Accordeon
                            title={<VacancyTitle item={el}/>}
                            inner={<VacancyInner item={el}/>}
                        />
                    })}
                </div> : <Loader/>}
                <div className="container vacancies-page__form">
                    <div className="vacancies-page__form-holder">
                        <VacanciesForm/>
                    </div>
                </div>
        </div>
    }
}

const VacancyTitle = ({item}) => {
    return <div className="vacancy-outer">
        <div className="vacancy-title">{item.title}</div>
    </div>
};

const VacancyAdvantages = () => {
    return <div className="vacancy-advantages">
        <img className={'vacancy-advantages-shpinat1'} src={shpinat1} alt=""/>
        <img className={'vacancy-advantages-shpinat2'} src={shpinat2} alt=""/>
        <div className="vacancy-advantages__title text--handwritten">
            <span className="color-black">Преимущества работы</span><br/>
            <span className="color-green">в Koreana Light</span>
        </div>
        <ol className="vacancy-advantages__list">
            <li className={'vacancy-advantages__item'}>Команда единомышленников</li>
            <li className={'vacancy-advantages__item'}>Горящие глаза</li>
            <li className={'vacancy-advantages__item'}>Молодые лидеры</li>
            <li className={'vacancy-advantages__item'}>Грандиозные планы по захвату мира</li>
            <li className={'vacancy-advantages__item'}>Карьерный рост</li>
            <li className={'vacancy-advantages__item'}>Постоянное развитие</li>
        </ol>
    </div>
}
const VacancyInner = ({item}) => {
    const {context} = useContext(AppContext);

    const open = (title) => {
        context.modals.vacancy.dispatch({type: "open", payload: title})
    }

    return <div className="vacancy-inner">
        {!context.settings.isMobile ? <VacancyAdvantages/> : null}
        <div className="vacancy-requirements">
            <div className="vacancy-requirements__item">
                <div className="vacancy-requirements__title">Основные обязанности</div>
                <div className="vacancy-requirements__description">{item.description}</div>
                <div className="vacancy-requirements__title">Требования</div>
                <div className="vacancy-requirements__description">
                    <ul>
                        {Object.values(item.requirements).map(el => {
                            return <li>{el}</li>
                        })}
                    </ul>
                </div>

                <div className="vacancy-requirements__title">Преимущества</div>
                <div className="vacancy-requirements__description">
                    <ul>
                        {Object.values(item.duties).map(el => {
                            return <li>{el}</li>
                        })}
                    </ul>
                </div>
            </div>
            <Button onClick={() => {open(item.title)}} color={'green'} text={'Отправить заявку'}/>
        </div>
    </div>
}

Vacancies.contextType = AppContext;