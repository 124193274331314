import React from 'react'
import Slider from "react-slick";

import arrow_left from "../../static/icons/arrow_left.svg"
import arrow_right from "../../static/icons/arrow_right.svg"
import quotes from "../../static/icons/quotes.svg"
import instagram from "../../static/icons/vk.svg"
import {AppContext} from "../../store/context";

export class TestimonialSlider extends React.Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.state = {
            testimonials: props.testimonials,
        }
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            slidesToShow: this.context.context.settings.isMobile ? 1 : 2,
            speed: 500,
            arrows: false,
            draggable: false,
            variableWidth: true,
        };

        return <div className="testimonials__slider">
            <Slider ref={c => (this.slider = c)} {...settings}>
                {this.state.testimonials.map(el => {
                    return <TestimonialCard testimonial={el}/>
                })}
            </Slider>
            <div className='testimonials__slider-arrows'>
                <div className="slider-arrow" onClick={this.previous}>
                    <img src={arrow_left} alt=""/>
                </div>
                <div className="slider-arrow" onClick={this.next}>
                    <img src={arrow_right} alt=""/>
                </div>
            </div>
        </div>
    }
}

TestimonialSlider.contextType = AppContext;

export const TestimonialCard = ({testimonial}) => {
    const monthNames = ["январь",
        "февраль", "март", "апрель", "май", "июнь",
        "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
    ];

    const date = new Date(testimonial.date.date);

    return <div className="testimonials__card">
        <div className="testimonials__card-heading">
            <div className="testimonials__card-title">
                <div className="testimonials__card-title_text text">{testimonial.name}</div>
                <div className="testimonials__card-title_date">{`${monthNames[date.getMonth()]}, ${date.getFullYear()}`}</div>
            </div>
            <div className="testimonials__card-title_icon"><img src={instagram} alt=""/></div>
        </div>
        <div className="testimonials__card-body">
            <div className="testimonials__card-body_title">{testimonial.title}</div>
            <div className="testimonials__card-body_summary">{testimonial.summary}</div>
        </div>
        <div className="testimonials__card-footer">
            <a target="_blank" href={testimonial.link} className="testimonials__card-footer_link">читать весь отзыв</a>
            <div className="testimonials__card-footer_icon"><img src={quotes} alt=""/></div>
        </div>
    </div>
};