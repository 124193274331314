import React, {useContext} from 'react';
import {Link} from 'react-router-dom'

import logo from '../static/logo-white.svg'


import vkontakte from '../static/icons/vk.svg'
import youtube from '../static/icons/yt.svg'
import instagram from '../static/icons/inst-filled.svg'
import payment from '../static/icons/payment.png'
import madvarejka from '../static/icons/madvarejka.svg'
import {AppContext} from "../store/context";

const MobileFooter = () => {
    return <div className="footer">
        <div className={'container footer-links'}>
            <div className="footer-logo"><img src={logo} alt=""/></div>
            <div className="footer-menu">
                <div className="footer-menu__col">
                    <a className="footer-menu__col-item footer-menu__col-item--phone" href="tel:+78124907464">490-74-64</a>
                    <Link className="footer-menu__col-item" to={'/contacts'}>контакты</Link>
                    {/*<Link className="footer-menu__col-item" to={'/#faq'}>вопрос/ответ</Link>*/}
                    <Link className="footer-menu__col-item" to={'/restaurants'}>рестораны</Link>
                    <Link className="footer-menu__col-item" to={'/terms-of-use'}>политика конфиденциальности</Link>
                </div>
                <div className="footer-menu__col">
                    {/*<Link className="footer-menu__col-item" to={'/'}>главная</Link>*/}
                    <Link className="footer-menu__col-item" to={'/menu'}>меню</Link>
                    {/*<Link className="footer-menu__col-item" to={'/about'}>о нас</Link>*/}
                    <Link className="footer-menu__col-item" to={'/vacancies'}>вакансии</Link>
                    <Link className="footer-menu__col-item" to={'/payment-and-delivery'}>предзаказ и оплата</Link>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="footer-icons">
                <div className="footer-icons__row">
                    <img src={payment} alt=""/>
                </div>
                <div className="footer-icons__row">
                    {/*<img src={instagram} alt=""/>*/}
                    <a href="https://vk.com/koreanalight" target="_blank" rel="noreferrer noopener">
                        <img src={vkontakte} alt=""/>
                    </a>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="copyright">© Кореана light</div>
                <div className="design"><img src={madvarejka} alt=""/></div>
            </div>
        </div>
    </div>
};

const DesktopFooter = () => {
    return <div className="footer">
        <div className={'container'}>
            <div className="footer-col">
                <div className="footer-logo"><img src={logo} alt=""/></div>
                <div className="footer-menu">
                    <div className="footer-menu__col">
                        {/*<Link className="footer-menu__col-item" to={'/'}>главная</Link>*/}
                        <Link className="footer-menu__col-item" to={'/menu'}>меню</Link>
                        {/*<Link className="footer-menu__col-item" to={'/about'}>о нас</Link>*/}
                        <Link className="footer-menu__col-item" to={'/restaurants'}>рестораны</Link>
                        <Link className="footer-menu__col-item" to={'/contacts'}>контакты</Link>
                    </div>
                    <div className="footer-menu__col">
                        <Link className="footer-menu__col-item" to={'/vacancies'}>вакансии</Link>
                        <Link className="footer-menu__col-item" to={'/payment-and-delivery'}>предзаказ и оплата</Link>
                        <Link className="footer-menu__col-item" to={'/terms-of-use'}>политика конфиденциальности</Link>
                    </div>
                    <div className="footer-menu__col">

                    </div>
                </div>
            </div>
            <div className="footer-col">
                <div className="footer-icons">
                    <div className="footer-icons__row">
                        <img src={payment} alt=""/>
                    </div>
                    <div className="footer-icons__row">
                        {/*<img src={instagram} alt=""/>*/}
                        <a href="https://vk.com/koreanalight" target="_blank" rel="noreferrer noopener">
                            <img src={vkontakte} alt=""/>
                        </a>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="copyright">© Кореана light</div>
                    <div className="design"><img src={madvarejka} alt=""/></div>
                </div>
            </div>
        </div>
    </div>
};

const Footer = () => {
    const {context} = useContext(AppContext);

    return context.settings.isMobile
        ? <MobileFooter/>
        : <DesktopFooter/>
};

export default Footer
