import {useContext, useRef, useState} from "react";
import {AppContext} from "../store/context";
import {useHistory} from 'react-router-dom'

import InputMask from "react-input-mask";
import {RestaurantPickupDropdown, TimeDropdown} from "../components/Dropdown";

import checkbox from "../static/icons/checkbox.svg"
import checkbox_active from "../static/icons/checkbox_active.svg"

import radio from "../static/icons/radio.svg"
import radio_active from "../static/icons/radio_active.svg"

const generateTimes = () => {
    const times = [];
    const now = new Date();

    if (now.getHours() < 11) {
        now.setHours(10);
        now.setMinutes(31);
    }

    const endHour = 21;
    const stepMinutes = 30;

    let hour = now.getHours();
    let minutes = Math.floor(now.getMinutes() / stepMinutes) * stepMinutes + stepMinutes;

    while (hour < endHour) {
        minutes += stepMinutes;

        if (minutes >= 60) {
            hour += 1;
            minutes = 0;
        }

        times.push({hour: hour, minutes: minutes})
    }

    times.pop();

    return times;
}

const CheckoutDeliveryTimeSelector = ({time, callback, selectTime}) => {
    const times = generateTimes();

    return <div className="delivery-time">
        <div className="delivery-time__item" onClick={() => {
            callback({...time, asap: true})
        }}>
            <div className="delivery-time__item--icon">{time.asap === true ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="delivery-time__item--value">на ближайшее</div>
        </div>
        {time.asap == true ?
            <span className={'delivery-time__item--disclaimer'}>*Ваш заказ будет готов в течение 15 минут</span> : null}
        <div className="delivery-time__item" onClick={() => {
            callback({...time, asap: false})
        }}>
            <div className="delivery-time__item--icon">{time.asap === false ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="delivery-time__item--value">на определенное</div>
        </div>
        {time.asap === true
            ? null
            : <TimeDropdown values={times} callback={selectTime}/>
        }
    </div>
};

const CheckoutPaymentTypeSelector = ({payment, callback}) => {
    return <div className="payment-type">
        <div className="payment-type__item" onClick={() => {
            callback('cash')
        }}>
            <div className="payment-type__item--icon">{payment.type === 'cash' ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="payment-type__item--value">наличными</div>
        </div>
        <div className="payment-type__item" onClick={() => {
            callback('card')
        }}>
            <div className="payment-type__item--icon">{payment.type === 'card' ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="payment-type__item--value">картой</div>
        </div>
        {/*<div className="payment-type__item" onClick={() => {*/}
        {/*callback('online')*/}
        {/*}}>*/}
        {/*<div className="payment-type__item--icon">{payment.type === 'online' ? <img src={radio_active} alt=""/> :*/}
        {/*<img src={radio} alt=""/>}</div>*/}
        {/*<div className="payment-type__item--value">картой*/}
        {/*</div>*/}
        {/*</div>*/}
    </div>
};

const CheckoutPickupTypeSelector = ({pickup, callback}) => {
    return <div className="pickup-type">
        <div className="pickup-type__item" onClick={() => {
            callback('indoor')
        }}>
            <div className="pickup-type__item--icon">{pickup.type === 'indoor' ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="pickup-type__item--value">в ресторане</div>
        </div>
        <div className="pickup-type__item" onClick={() => {
            callback('takeaway')
        }}>
            <div className="pickup-type__item--icon">{pickup.type === 'takeaway' ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="pickup-type__item--value">заберу с собой
            </div>
        </div>
    </div>
};


const PersonsSelector = ({persons, callback}) => {
    return <div className="delivery-time">
        <div className="delivery-time__item" onClick={() => {
            callback(0)
        }}>
            <div className="delivery-time__item--icon">{persons === 0 ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="delivery-time__item--value">без приборов</div>
        </div>
        <div className="delivery-time__item" onClick={() => {
            callback(1)
        }}>
            <div className="delivery-time__item--icon">{persons > 0 ? <img src={radio_active} alt=""/> :
                <img src={radio} alt=""/>}</div>
            <div className="delivery-time__item--value">положить приборы</div>
        </div>
        {persons > 0
            ? <PersonsCounter persons={persons} callback={callback}/>
            : null
        }
    </div>
};

const PersonsCounter = ({persons, callback}) => {
    return <div className="persons-counter">
        <div className="persons-counter--minus" onClick={() => {
            if (persons > 1) callback(persons - 1)
        }}>-</div>
        <div className="persoms-counter--value">{persons}</div>
        <div className="persons-counter--plus" onClick={() => {
            callback(persons + 1)
        }}>+</div>
    </div>
}

export const Checkout = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const checkboxref = useRef(null);
    const verifiedref = useRef(null);
    const formRef = useRef(null);

    const [codeInProgress, setCodeInProgress] = useState(null);

    const [state, setState] = useState({
        name: {
            value: '',
            disabled: false,
        },
        phone: {
            value: '',
            disabled: false,
        },
        comment: {
            value: '',
        },
        time: {
            asap: true,
            value: {
                day: '',
                hour: '',
                minute: ''
            },
        },
        persons: 0,
        payment: {
            type: 'cash'
        },
        pickup: {
            type: 'indoor'
        },
        agree: false,
        verification: {
            verified: false,
            showForm: false,
            code: '',
        }
    });

    const setPhone = (val) => {
        if (state.verification.verified) {
            setState({
                ...state,
                verification: {...state.verification, code: null, showForm: false, verified: false},
                phone: {...state.phone, value: val}
            });
            verifiedref.current.checked = false;
            return;
        }

        setState({...state, phone: {...state.phone, value: val}})
    };

    const setName = (val) => {
        setState({...state, name: {...state.name, value: val}})
    };

    const setComment = (val) => {
        setState({...state, comment: {...state.comment, value: val}})
    };

    const pickupSelectorCallback = (type) => {
        setState({...state, pickup: {type: type}})
    };

    const personsSelectorCallback = (count) => {
        setState({...state, persons: count})
    };

    const paymentSelectorCallback = (type) => {
        setState({...state, payment: {type: type}})
    };

    const timeSelectorCallback = (time) => {
        setState({...state, time: time})
    };

    const preorderCallback = (time) => {
        setState({
            ...state, time: {
                asap: false,
                value: {
                    day: '',
                    hour: time.hour,
                    minute: time.minutes
                },
            }
        })
    };

    const toggleAgreement = () => {
        checkboxref.current.checked = !state.agree;
        setState({...state, agree: !state.agree})
    };

    const toggleVerified = () => {
        const phone = state.phone.value;

        if (phone.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        const phoneUnmasked = phone.match(/\d+/g).join('');

        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!state.verification.showForm && !state.verification.verified) {
            context.processor.cart.verifyPhone(phoneUnmasked).then(() => {
                setState({...state, verification: {...state.verification, showForm: true}});
            }).catch(err => {
                if (err.code === 400) {
                    context.toast.error(err.message);
                    return
                }

                context.toast.error("Что-то пошло не так.. Пожалуйста, попробуйте позже")
            })
        }
    };

    const verifyPhone = (val) => {
        setState({...state, verification: {...state.verification, code: val}});

        if (val) {
            const matches = val.match(/\d+/g);

            const phone = state.phone.value;

            if (phone.length < 11) {
                context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
                return;
            }

            const phoneUnmasked = phone.match(/\d+/g).join('');

            if (phoneUnmasked.length < 11) {
                context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
                return;
            }

            if (matches && matches.length === 4) {
                const code = matches.join('');
                setCodeInProgress(true);

                context.processor.cart.verifyCode(phoneUnmasked, code).then(() => {
                    setState({
                        ...state,
                        verification: {...state.verification, code: null, showForm: false, verified: true}
                    });
                    verifiedref.current.checked = true;
                }).catch(err => {
                    if (err.code === 400) {
                        context.toast.error(err.message);
                        return
                    }

                    context.toast.error("Что-то пошло не так.. Пожалуйста, попробуйте позже")
                }).finally(() => {
                    setCodeInProgress(false)
                })
            }
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (state.name.value.length < 2) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (state.phone.value.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!state.verification.verified) {
            context.toast.error('Пожалуйста, подтвердите номер телефона');
            return;
        }

        const data = {
            name: state.name.value,
            phone: state.phone.value.match(/\d+/g).join(''),
            comment: state.comment.value,
            time: state.time,
            payment: state.payment,
            pickup: state.pickup,
            persons: state.persons
        };

        if (data.phone.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        context.processor.order.checkout(data)
            .then(res => {
                context.cart.dispatch({type: 'clearCart'});

                return res
            })
            .then(res => {
                if (res.paymentUrl) {
                    window.location.replace(res.paymentUrl);
                    return;
                }

                history.push(`/order/success/${res.orderHash}`);
            }).catch(err => {
            context.toast.error(err.message)
        })
    };

    let value = context.cart.state.sum;

    return <div className='checkout'>
        <div className="checkout__title">
            <span>Оформление</span> <span>заказа</span>
        </div>
        <form ref={formRef} onSubmit={handleSubmit} className="checkout__form">
            <div className="checkout__details">
                <div className="checkout__details--input">
                    <input type="text" onChange={e => setName(e.target.value)} placeholder={'Имя'}
                           value={state.name.value} disabled={state.name.disabled}/>
                </div>
                <div className="checkout__details--input">
                    <InputMask value={state.phone.value}
                               formatChars={{
                                   '9': '[0-9]',
                                   '7': '[7-8]'
                               }}
                               onChange={e => setPhone(e.target.value)}
                               mask="+7 (999) 999-99-99"
                               disabled={state.phone.disabled || codeInProgress}
                               placeholder={'Телефон'}
                               maskChar="_"/>
                </div>
                <div className="checkout__details--input">
                    <input type="text" onChange={e => setComment(e.target.value)} placeholder={'Комментарий к заказу'}
                           value={state.comment.value}/>
                </div>
            </div>
            <div className="checkout__delivery">
                <div className="checkout__delivery-time">
                    <div className="checkout__label">На какое время:</div>
                    <CheckoutDeliveryTimeSelector time={state.time} callback={timeSelectorCallback}
                                                  selectTime={preorderCallback}/>
                </div>
                <div className="checkout__delivery-pickup">
                    <div className="checkout__label">Выберите ресторан:</div>
                    <RestaurantPickupDropdown/>
                </div>
            </div>
            <div className="checkout__payment">
                <div className="checkout__payment-type">
                    <div className="checkout__label">Форма оплаты:</div>
                    <CheckoutPaymentTypeSelector payment={state.payment} callback={paymentSelectorCallback}/>
                </div>
            </div>
            <div className="checkout__payment">
                <div className="checkout__payment-type">
                    <div className="checkout__label">Заказ:</div>
                    <CheckoutPickupTypeSelector pickup={state.pickup} callback={pickupSelectorCallback}/>
                </div>
            </div>
            <div className="checkout__payment">
                <div className="checkout__payment-type">
                    <div className="checkout__label">Столовые приборы:</div>
                    <PersonsSelector persons={state.persons} callback={personsSelectorCallback}/>
                </div>
            </div>
            <div className="checkout__confirm" onClick={() => {
                toggleVerified()
            }}>
                <div className="checkout__confirm-checkbox">
                    <input id={'checkout-agree'} type="checkbox"
                           style={{height: "1px", width: "1px", border: "none", display: "flex"}} ref={verifiedref}
                           required={true}/>
                    {state.verification.verified ? <img src={checkbox_active} alt=""/> : <img src={checkbox} alt=""/>}
                </div>
                <div className="checkout__confirm-text">
                    <span>Подтверждение номера телефона</span>
                </div>
            </div>
            {state.verification.showForm ?
                <div className="checkout__details--input checkout__confirm--input">
                    <InputMask value={state.verification.code}
                               formatChars={{
                                   '9': '[0-9]',
                               }}
                               onChange={e => verifyPhone(e.target.value)}
                               mask="9 9 9 9"
                               disabled={codeInProgress}
                               placeholder={'Код из СМС'}
                               maskChar="_"/>
                </div> : null}
            <div className="checkout__agree" onClick={() => {
                toggleAgreement()
            }}>
                <div className="checkout__agree-checkbox">
                    <input id={'checkout-agree'} type="checkbox"
                           style={{height: "1px", width: "1px", border: "none", display: "flex"}} ref={checkboxref}
                           required={true}/>
                    {state.agree ? <img src={checkbox_active} alt=""/> : <img src={checkbox} alt=""/>}</div>
                <div className="checkout__agree-text">
                    <a href="/terms-of-use" target='_blank'>Согласен с Политикой обработки персональных данных и
                        Соглашением об условиях доставки</a>
                </div>
            </div>
            <div className="checkout__sum desktop">
                <div className="checkout__sum-label">СУММА ЗАКАЗА:</div>
                <div className="checkout__sum-value">{value}₽</div>
            </div>
            <div className="checkout__order-button desktop">
                <button className={`button button-${state.agree && state.verification.verified ? 'green' : 'gray'}`}>
                    <span>НАЧАТЬ ГОТОВИТЬ</span>
                </button>
            </div>
            <div className="checkout__order-sum mobile">
                <div className="checkout__order-summary">
                    <div className="checkout__sum-label">ИТОГО:</div>
                    <div className="checkout__sum-value">{value}₽</div>
                </div>
                <button className={`button button-${state.agree && state.verification.verified ? 'green' : 'gray'}`}>
                    <span>НАЧАТЬ ГОТОВИТЬ</span>
                </button>
            </div>
        </form>
    </div>
};
