import {useContext, useRef, useState, useReducer, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import InputMask from "react-input-mask";
import {BottomSheet} from 'react-spring-bottom-sheet'

import {Button} from "./Buttons";
import {Title, TitleWGap} from "./Typo";
import {AppContext} from "../store/context";
import {CartProducts} from '../components/Cart'
import {STATIC_HOST} from "../const";
import {Timer} from "./Timer";
import {VacanciesFormInner} from "./Form";
import {CartSauce, ProductCardSlider} from "./product/Card";

import radio from "../static/icons/radio.svg"
import radio_active from "../static/icons/radio_active.svg"
import arrow_left from '../static/icons/arrow_left.svg'
import arrow_right from '../static/icons/arrow_right.svg'
import checkbox from "../static/icons/checkbox.svg"
import checkbox_active from "../static/icons/checkbox_active.svg"
import cross from '../static/icons/cross.svg'

import g1 from '../static/images/modals/g1.png'
import g2 from '../static/images/modals/g2.png'
import list from '../static/images/modals/list.png'
import Slider from "react-slick";

export const ModalHolder = ({children}) => {
    const {context} = useContext(AppContext);

    return <div className="modals">
        {context.settings.isMobile ? children : <ModalOverlay children={children}/>}
    </div>
};

export const ResponsiveVacancyModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.modals.vacancy.dispatch({type: 'close'});
    };

    const Desktop = ({children}) => {
        return <div className={`modal ${context.modals.vacancy.state.open ? 'modal--open' : ''} vacancy-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            {children}
        </div>
    };

    const Mobile = ({children}) => {
        return <BottomSheet
            class={"small-modal"}
            header={<div className="modal-header-clearfix"></div>}
            open={context.modals.vacancy.state.open} onDismiss={onDissmiss}>
            <div className="vacancy-modal">
                {children}
            </div>
        </BottomSheet>
    };

    return context.settings.isMobile
        ? <Mobile>
            <VacancyModal/>
        </Mobile>
        : <Desktop>
            <VacancyModal/>
        </Desktop>
}

export const ResponsivePickupModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.modals.restaurant.dispatch({type: 'close'});
    };

    const callback = (event) => {
        if (!context.modals.restaurant.state.selected) {
            return;
        }

        context.processor.cart.selectPickupRestaurant(context.modals.restaurant.state.selected).then(res => {
            context.cart.dispatch({
                type: 'setZone',
                payload: {
                    zone: {
                        id: res.cart.delivery.id,
                        group: res.cart.delivery.group,
                        distance: res.cart.delivery.distance,
                    }
                }
            });

            // if (res.removed === true) {
            context.cart.dispatch({type: 'setCart', payload: res.cart});
            // }
        }).then(() => {
            if (context.modals.restaurant.state.product) {
                const product = context.modals.restaurant.state.product;

                context.processor.cart.addProduct({product: product.product.id}).then((response) => {
                    context.cart.dispatch({type: 'setBubblePos', payload: {x: event.clientX, y: event.clientY}});
                    context.cart.dispatch({type: 'setCart', payload: response.cart})
                }).catch(err => {
                    if (err.code === 400) {
                        context.toast.error(err.message)
                    }
                });
            }
        }).then(() => {
            context.modals.restaurant.dispatch({type: 'close'});
        }).catch(err => {
            context.toast.warn('Ресторан временно недоступен')
        })
    };

    return context.settings.isMobile
        ? <BottomSheet open={context.modals.restaurant.state.open}
                       className={'with-header'}
                       onDismiss={onDissmiss}
                       header={
                           <>
                               <div className="modal-header-clearfix"></div>
                               <div className="zone-modal__title">
                                   <TitleWGap black={'ВЫБЕРИТЕ, ПОЖАЛУЙСТА,'} green={'УДОБНЫЙ ДЛЯ ВАС РЕСТОРАН'}/>
                               </div>
                           </>
                       }
                       footer={<div className="zone-modal__button">
                           <Button color={'green'} text={'ВЫБРАТЬ'} onClick={(e) => callback(e)}/>
                       </div>}
                       snapPoints={({maxHeight}) => maxHeight / 1.6}>
            <div className="zone-modal__sheet">
                <SelectPickupModal/>
            </div>
        </BottomSheet>
        : <div className={`modal ${context.modals.restaurant.state.open ? 'modal--open' : ''} zone-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            <div className="zone-modal__title">
                <TitleWGap black={'ВЫБЕРИТЕ, ПОЖАЛУЙСТА,'} green={'УДОБНЫЙ ДЛЯ ВАС РЕСТОРАН'}/>
            </div>
            <SelectPickupModal/>
            <div className="zone-modal__button">
                <Button color={'green'} text={'ВЫБРАТЬ'} onClick={(e) => callback(e)}/>
            </div>
        </div>
};

export const ResponsiveActionModal = () => {
    const {context} = useContext(AppContext);

    const selected = context.modals.action.state.selected;

    const onDissmiss = () => {
        context.modals.action.dispatch({type: 'close'});
    };

    const Desktop = ({children}) => {
        return <div className={`modal ${context.modals.action.state.open ? 'modal--open' : ''} action-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            {children}
        </div>
    };

    const Mobile = ({children}) => {
        return <BottomSheet class={'action-modal with-picture'}
                            open={context.modals.action.state.open}
                            onDismiss={onDissmiss}
                            header={<div className="modal-header-clearfix"></div>}
                            footer={MobileFooter()}
        >
            <div className="action-modal-sheet">
                {children}
            </div>
        </BottomSheet>
    };

    const MobileFooter = () => {
        return selected && selected.button.link && selected.button.text ?
            <div className="action-modal-sheet__button">
                <a href={selected.button.link}
                   className={'button button-green'}>{selected.button.text} </a>
            </div> : null
    };

    return context.settings.isMobile
        ? <Mobile>
            <ActionModal/>
        </Mobile>
        : <Desktop>
            <ActionModal/>
        </Desktop>
}

export const ResponsiveProductModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.modals.product.dispatch({type: 'close'});
    };

    const MobileFooter = () => {
        const zone = context.cart.state.delivery.zone.group;
        const selected = context.modals.product.state.selected;

        let enabled = false;

        if (zone) {
            enabled = selected ? selected.zones.find(z => {
                return z.id === zone
            }) : false;
        } else {
            enabled = true
        }

        const callback = (event) => {
            const modifiers = [];
            modifiers.push(...Object.keys(context.modals.product.state.selectedModifiers.sauces))
            modifiers.push(...Object.keys(context.modals.product.state.selectedModifiers.toppings))

            context.processor.cart.addProduct({product: selected.id, modifiers: modifiers}).then((response) => {
                context.modals.product.dispatch({type: 'toggle'});
                context.cart.dispatch({type: 'setBubblePos', payload: {x: event.clientX, y: event.clientY}});
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            }).catch(err => {
                if (!zone) {
                    context.modals.restaurant.dispatch({
                        type: 'open',
                        payload: {product: {id: selected.id, modifiers: modifiers}}
                    });
                    return
                }
                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
        };

        return <div className="product-modal__buy">
            {enabled ? <Button color={'green'} text={'В КОРЗИНУ'} onClick={(e) => {
                callback(e);
            }}/> : null}
        </div>
    };

    return context.settings.isMobile
        ? <BottomSheet
            header={<div className="modal-header-clearfix"></div>}
            footer={MobileFooter()}
            class={'product-modal with-picture'} open={context.modals.product.state.open} onDismiss={onDissmiss}
            snapPoints={({maxHeight}) => maxHeight / 1.15}>
            <div className="product-modal__sheet">
                <ProductModal/>
            </div>
        </BottomSheet>
        : <div className={`modal ${context.modals.product.state.open ? 'modal--open' : ''} product-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            <ProductModal/>
        </div>
};

export const ResponsiveCartModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.cart.dispatch({type: 'close'});
    };

    let value = context.cart.state.sum;

    const Desktop = ({children}) => {
        return <div className={`modal ${context.cart.state.modal.open ? 'modal--open' : ''} cart-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            {children}
        </div>
    };

    const MobileFooter = () => {
        return <div className="cart-modal-sheet__summary">
            <div className="cart-modal-sheet__summary-title">
                <div className="cart-modal-sheet__summary-title--label">Итого:</div>
                <div className="cart-modal-sheet__summary-title--value">{value}₽</div>
            </div>
            <Link to={'/checkout'} className={'button button-green'} onClick={() => {
                context.cart.dispatch({type: 'close'})
            }}><span>Заказать</span></Link>
        </div>
    };

    return context.settings.isMobile
        ? <BottomSheet class={'product-modal'}
                       open={context.cart.state.modal.open}
                       header={<div className="modal-header-clearfix"></div>}
                       onDismiss={onDissmiss}
                       footer={MobileFooter()}
                       snapPoints={({maxHeight}) => maxHeight / 1.15}>
            <div className="cart-modal-sheet">
                <CartModal/>
            </div>
        </BottomSheet>
        : <Desktop>
            <CartModal/>
        </Desktop>
};

export const ResponsiveAuthModal = () => {
    const {context} = useContext(AppContext);

    const onDissmiss = () => {
        context.modals.auth.dispatch({type: 'close'});
    };

    const Desktop = ({children}) => {
        return <div className={`modal ${context.modals.auth.state.open ? 'modal--open' : ''} auth-modal`}>
            <div className="modal-cross" onClick={() => onDissmiss()}><img src={cross} alt=""/></div>
            {children}
        </div>
    };

    const Mobile = ({children}) => {
        return <BottomSheet
            class={"small-modal"}
            header={<div className="modal-header-clearfix"></div>}
            open={context.modals.auth.state.open} onDismiss={onDissmiss}
            snapPoints={({maxHeight}) => maxHeight / 1.4}>
            <div className="auth-modal__sheet">
                {children}
            </div>
        </BottomSheet>
    };

    return context.settings.isMobile
        ? <Mobile>
            <AuthModal/>
        </Mobile>
        : <Desktop>
            <AuthModal/>
        </Desktop>
};

const VacancyModal = () => {
    const {context} = useContext(AppContext);

    return <>
        <div className="vacancy-modal__title">
            <Title black={context.modals.vacancy.state.title}/>
        </div>
        <div className="vacancy-modal__inner">
            <VacanciesFormInner title={context.modals.vacancy.state.title}/>
        </div>
    </>
};

const SelectPickupModal = () => {
    const {context} = useContext(AppContext);

    const click = (zone) => {
        context.modals.restaurant.dispatch({type: 'select', payload: zone})
    };

    const restaurants = context.restaurants.state.items.filter(el => {
        return el.active === true;
    });

    const selected = context.modals.restaurant.state.selected;

    return <>
        <div className="zone-modal__inner">
            <img src={g1} className="zone-modal__inner-image zone-modal__inner-image--g1"/>
            <img src={g2} className="zone-modal__inner-image zone-modal__inner-image--g2"/>
            <img src={list} className="zone-modal__inner-image zone-modal__inner-image--list"/>
            {restaurants.map(el => {
                return <div className="zone-modal__item" onClick={() => {
                    click(el)
                }}>
                    <div className='zone-modal__item-radio'>
                        {selected ? el.title === selected.title ? <img src={radio_active} alt=""/> :
                            <img src={radio} alt=""/> : <img src={radio} alt=""/>}
                    </div>
                    <div className='zone-modal__item-text'>
                        <div className="zone-modal__item-text--title">{el.title}</div>
                        <div className="zone-modal__item-text--address">{el.address}</div>
                        <div className="zone-modal__item-text--link">
                            <a href={el.googleMaps} target="_blank">Открыть в Google Maps</a>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>
};

const ProductModal = () => {
    const {context} = useContext(AppContext);

    const zone = context.cart.state.delivery.zone.group;
    const selected = context.modals.product.state.selected;

    const sauces = context.modals.product.state.selectedModifiers.sauces;
    const toppings = context.modals.product.state.selectedModifiers.toppings;

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    let enabled = false;

    if (zone) {
        enabled = selected ? selected.zones.find(z => {
            return z.id === zone
        }) : false;
    } else {
        enabled = true
    }

    useEffect(() => {
        context.modals.product.dispatch({type: 'setModifiersSauces', payload: {}});
        context.modals.product.dispatch({type: 'setModifiersToppings', payload: {}});
    }, [selected])

    const callback = (event) => {
        const modifiers = [];
        modifiers.push(...Object.keys(sauces))
        modifiers.push(...Object.keys(toppings))

        context.processor.cart.addProduct({product: selected.id, modifiers: modifiers}).then((response) => {
            context.modals.product.dispatch({type: 'toggle'});
            context.cart.dispatch({type: 'setBubblePos', payload: {x: event.clientX, y: event.clientY}});
            context.cart.dispatch({type: 'setCart', payload: response.cart})
        }).catch(err => {
            if (!zone) {
                context.modals.restaurant.dispatch({
                    type: 'open',
                    payload: {product: {id: selected.id, modifiers: modifiers}}
                });
                return
            }
            if (err.code === 400) {
                context.toast.error(err.message)
            }
        });
    };

    const next = () => {
        const list = context.modals.product.state.list;
        if (!list) {
            return
        }
        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.slug === selected.slug
        });

        let nextElement = null;

        if (currentElementIndex === (length - 1)) {
            nextElement = list[0]
        } else {
            nextElement = list[currentElementIndex + 1]
        }

        context.modals.product.dispatch({type: 'openProduct', payload: {product: nextElement}})
    };

    const prev = () => {
        const list = context.modals.product.state.list;
        if (!list) {
            return
        }
        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.slug === selected.slug
        });

        let prevElement = null;

        if (currentElementIndex === 0) {
            prevElement = list[length - 1]
        } else {
            prevElement = list[currentElementIndex - 1]
        }

        context.modals.product.dispatch({type: 'openProduct', payload: {product: prevElement}})
    };

    const settings = {
        slidesToShow: 2,
        speed: 500,
        arrows: false,
        draggable: true,
        variableWidth: true,
        infinite: false,
    };

    const toggleModifier = (el) => {
        if (el.type === 'sauce') {
            let items = sauces
            if (items.hasOwnProperty(el.slug)) {
                delete items[el.slug]
            } else {
                if (isBlocked(el)) {
                    return
                }
                items[el.slug] = el.price
            }

            context.modals.product.dispatch({type: 'setModifiersSauces', payload: items});
        }

        if (el.type === 'topping') {
            let items = toppings
            if (items.hasOwnProperty(el.slug)) {
                delete items[el.slug]
            } else {
                if (isBlocked(el)) {
                    return
                }
                items[el.slug] = el.price
            }

            context.modals.product.dispatch({type: 'setModifiersToppings', payload: items});
        }

        forceUpdate()
    }

    const isBlocked = (el) => {
        if (el.type === 'sauce' && selected.modifiers.maxSauces > 0) {
            return Object.keys(sauces).length === selected.modifiers.maxSauces
        }

        if (el.type === 'topping' && selected.modifiers.maxToppings > 0) {
            return Object.keys(toppings).length === selected.modifiers.maxToppings
        }

        return false
    }

    const isActive = (el) => {
        if (el.type === 'sauce') {
            return sauces.hasOwnProperty(el.slug)
        }

        if (el.type === 'topping') {
            return toppings.hasOwnProperty(el.slug)
        }

        return false
    }

    const calculatePrice = () => {
        let price = selected.price

        Object.values(sauces).map(el => {
            price += el
        })

        Object.values(toppings).map(el => {
            price += el
        })

        if (selected.oldPrice) {
            return <>
                <span className={'muted'}>Сумма:</span>
                <span><s className={"product-modal-oldprice"}>{selected.oldPrice}</s> {price}₽</span>
            </>
        }

        return <>
            <span className={'muted'}>Сумма:</span>
            {price !== selected.price
                ? <span><s>{selected.price}</s> {price}₽</span>
                : <span>{price}₽</span>
            }
        </>
    }

    return <>
        <div className="product-modal__selector product-modal__selector--prev" onClick={() => {
            prev()
        }}><img src={arrow_left} alt=""/></div>
        {selected ?
            <div className="product-modal__inner">
                <div className="product-modal__image"
                     style={{backgroundImage: `url(${selected.images.full ? STATIC_HOST + selected.images.full.source : ''}`}}/>
                <div className="product-modal__content">
                    <div className="product-modal__title">
                        <span>{selected.title.ru}</span>
                        {selected.tags.find(el => el.slug === 'specakciya') ?
                            <div className={"specakciya"}>акция</div> : null}
                    </div>
                    <div className="product-modal__description">
                        <span>{selected.description.ru}</span>
                    </div>
                    <div className="product-modal__ingredients">
                        <span>Состав: {selected.ingredients.ru}</span>
                    </div>
                    <div className="product-modal__weight">
                        <span>{selected.weight} / {selected.nutrition.energy} ккал</span>
                    </div>
                    <div className="product-modal__nutrition">
                        <span>БЖУ: {selected.nutrition.protein} / {selected.nutrition.fat} / {selected.nutrition.carbohydrates}</span>
                    </div>
                    <div className="product-modal__line"/>
                    {selected.modifiers.list.length ?
                        <div className="product-modal__modifiers">
                            <div className="product-modal__toppings_title">добавьте топпинг:</div>
                            <div className="product-modal__toppings_list">
                                <Slider {...settings}>
                                    {selected.modifiers.list.map(el => {
                                        return <div className={`product-modal__topping
                                            ${isActive(el) ? 'product-modal__topping-active' : isBlocked(el) ? 'product-modal__topping-blocked' : ''}`}
                                                    onClick={() => {
                                                        toggleModifier(el)
                                                    }}>
                                            <div className="product-modal__topping-image"
                                                 style={{backgroundImage: `url(${STATIC_HOST + el.image.source})`}}></div>
                                            <div className="product-modal__topping-body">
                                                <div className="product-modal__topping-name">{el.name}</div>
                                                <div className="product-modal__topping-desc">
                                                    <div className="product-modal__topping-weight">{el.weight}</div>
                                                    <div className="product-modal__topping-price">+ {el.price}₽</div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </Slider>
                            </div>
                        </div>
                        : null}
                    <div className="product-modal__price">
                        {calculatePrice()}
                    </div>
                    {!context.settings.isMobile ?
                        <div className="product-modal__buy">
                            {enabled ? <Button color={'green'} text={'В КОРЗИНУ'} onClick={(e) => {
                                callback(e)
                            }}/> : null}
                        </div> : null}
                </div>
            </div> : null}
        <div className="product-modal__selector product-modal__selector--next" onClick={() => {
            next()
        }}><img src={arrow_right} alt=""/></div>
    </>
};

const CartModal = () => {
    const {context} = useContext(AppContext);

    const products = Object.entries(context.cart.state.products).map(el => {
        return {
            ulid: el[0],
            ...el[1]
        }
    });

    const sauces = context.catalog.state.recommendations
        ? Object.entries(context.catalog.state.recommendations.sauces).map(el => {
            return {
                ulid: el[0],
                ...el[1]
            }
        }) : [];

    const dop = context.catalog.state.recommendations
        ? Object.entries(context.catalog.state.recommendations.dop).map(el => {
            return {
                ulid: el[0],
                ...el[1]
            }
        }) : [];

    let val = 0;
    products.forEach(el => {
        val += el.price * el.quantity
    });


    return <>
        <div className="cart-modal__inner">
            <div className="cart-modal__title">
                <span>ВАШ ЗАКАЗ</span>
            </div>
            <div className="cart-modal__products">
                <CartProducts products={products}/>
            </div>
            {context.settings.isMobile ?
                <>
                    <div className="cart-modal__sauces">
                        {sauces.length > 0
                            ? <>
                                <div className="title">
                                    <span>Не забудьте добавить</span>
                                </div>
                                <div className="cart-sauces__slider">
                                    {sauces.map(product => {
                                        return <CartSauce product={product}/>
                                    })}
                                </div>
                            </>
                            : null}
                    </div>
                    <div className="cart-modal__recommendations">
                        {dop.length > 0
                            ? <>
                                <div className="title">
                                    <span>Рекомендуем попробовать</span>
                                </div>
                                <ProductCardSlider small={true} products={dop}/>
                            </>
                            : null}
                    </div>
                </> : null
            }
            <div className="cart-modal__summary">
                <div className="cart-modal__summary-title">
                    <div className="cart-modal__summary-title--label">Итого:</div>
                    <div className="cart-modal__summary-title--value">{val}₽</div>
                </div>
                <Link to={'/cart'} className={'button button-green'} onClick={() => {
                    context.cart.dispatch({type: 'close'})
                }}><span>Перейти в корзину</span></Link>
            </div>
        </div>
    </>
};

const AuthModal = () => {
    const {context} = useContext(AppContext);

    const history = useHistory();

    const [state, setState] = useState({
        phone: {
            value: null,
        },
        otp: {
            value: null,
        },
        otpSent: false,
        otpInProgress: false,
        otpSeconds: 60
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        sendOtp();
    };

    const setPhone = (val) => {
        setState({...state, phone: {...state.phone, value: val}})
    };

    const setOtp = (val) => {
        const code = val.replace(/\D/g, '');

        if (code.length === 4) {
            setState({...state, otpInProgress: true, otp: {...state.otp, value: val}});

            const phone = state.phone.value.replace(/\D/g, '');

            if (phone && phone.length === 11 && code && code.length === 4) {
                context.processor.user.verifyCode(code, phone)
                    .then(res => {
                        // context.user.dispatch({type: 'reload'});
                        context.modals.auth.dispatch({type: 'close'});
                    })
                    .then(() => {
                        history.push('/personal')
                    })
                    .catch(err => {
                        setState({...state, otpInProgress: false});
                    });
            }

            return;
        }

        setState({...state, otp: {...state.otp, value: val}})
    };

    const sendOtp = () => {
        const phone = state.phone.value.replace(/\D/g, '');

        if (phone && phone.length === 11) {
            context.processor.user.authByPhone(phone).then(res => {
                setState({...state, otpSent: true});
            }).catch(err => {
                context.toast.error(err.message)
            });
        } else {
            context.toast.error('Проверьте корректность введенного телефона');
        }
    };

    return <>
        <div className="auth-modal__inner">
            <div className="auth-modal__heading">
                <div className="auth-modal__title">Привествуем вас!</div>
                {state.otpSent === false
                    ? <div className="auth-modal__subtitle">Введите номер телефона для авторизации</div>
                    : <div className="auth-modal__subtitle">Введите код из смс для авторизации</div>}
            </div>
            <div className="auth-modal__body">
                {state.otpSent === false
                    ? <form onSubmit={handleSubmit}>
                        <div className="auth-modal__body-input auth-modal__body-input--phone">
                            <InputMask value={state.phone.value}
                                       formatChars={{
                                           '9': '[0-9]',
                                           '7': '[7-8]'
                                       }}
                                       onChange={e => setPhone(e.target.value)}
                                       mask="+7 (999) 999-99-99"
                                       disabled={state.otpSent}
                                       required={true}
                                       placeholder={'Телефон'}
                                       maskChar="_"/>
                        </div>
                        <AgreementCheckbox disabled={state.otpSent}/>
                        <div className="auth-modal__body-next">
                            <button className={'button button-green'}>
                                <span>Продолжить</span>
                            </button>
                        </div>
                    </form>
                    :
                    <>
                        <div className="auth-modal__body-input auth-modal__body-input--code">
                            <InputMask value={state.otp.value}
                                       onChange={e => setOtp(e.target.value)}
                                       mask="9 9 9 9"
                                       disabled={state.otpInProgress}
                                       required={true}
                                       placeholder={'Код из СМС'}
                                       maskChar="_"/>
                            <Timer seconds={60}/>
                        </div>
                    </>
                }
            </div>

            <div className="auth-modal__footer">
                <div className="auth-modal__footer-disclamer">
                    {state.otpSent === false
                        ? 'На указанный номер вам будет отправлено смс с кодом подтверждения'
                        : `На номер ${state.phone.value} отправлена смс с кодом подтверждения`}
                </div>
            </div>
        </div>
    </>
};

const ActionModal = () => {
    const {context} = useContext(AppContext);

    let selected = context.modals.action.state.selected;

    const next = () => {
        const list = context.modals.action.state.list;
        if (!list) {
            return
        }

        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.id === selected.id
        });

        let nextElement = null;

        if (currentElementIndex === (length - 1)) {
            nextElement = list[0]
        } else {
            nextElement = list[currentElementIndex + 1]
        }

        context.modals.action.dispatch({type: 'select', payload: nextElement})
    };

    const prev = () => {
        const list = context.modals.action.state.list;
        if (!list) {
            return
        }

        const length = list.length;

        if (length <= 1) {
            return;
        }

        const currentElementIndex = list.findIndex((element, index, array) => {
            return element.id === selected.id
        });

        let prevElement = null;

        if (currentElementIndex === 0) {
            prevElement = list[length - 1]
        } else {
            prevElement = list[currentElementIndex - 1]
        }

        context.modals.action.dispatch({type: 'select', payload: prevElement})
    };

    return <>
        <div className="action-modal__selector action-modal__selector--prev" onClick={() => {
            prev()
        }}>
            <img src={arrow_left} alt=""/>
        </div>
        {selected ?
            <div className="action-modal__inner">
                <div className="action-modal__image"
                     style={{backgroundImage: `url(${selected.image ? STATIC_HOST + selected.image.source : ''}`}}/>
                <div className="action-modal__content">
                    <div className="action-modal__title">
                        <span className={'color-black'}>{selected.title.one}</span><br/>
                        <span className={'color-green'}>{selected.title.two}</span>
                    </div>
                    <div className="action-modal__description">
                        <span>{selected.description}</span>
                    </div>
                    <div className="action-modal__line"/>
                    {selected.disclamer ? <div className="action-modal__disclamer">{selected.disclamer}</div> : null}
                    {selected.button.link && selected.button.text ?
                        <div className="action-modal__button">
                            <a href={selected.button.link}
                               className={'button button-green'}>{selected.button.text} </a>
                        </div> : null}
                </div>
            </div> : null}
        <div className="product-modal__selector product-modal__selector--next" onClick={() => {
            next()
        }}><img src={arrow_right} alt=""/></div>
    </>
};

const AgreementCheckbox = () => {
    const [state, setState] = useState({
        agreement: false
    });

    const checkboxref = useRef(null);

    const toggleAgreement = () => {
        checkboxref.current.checked = !state.agreement;
        setState({...state, agreement: !state.agreement})
    };

    return <div className="auth-modal__body-agreement" onClick={() => {
        toggleAgreement()
    }}>
        <div className="agreement__confirm-checkbox">
            <input id={'agreement-agree'} type="checkbox"
                   style={{height: "1px", width: "1px", border: "none", display: "flex"}} ref={checkboxref}
                   required={true}/>
            {state.agreement ? <img src={checkbox_active} alt=""/> : <img src={checkbox} alt=""/>}
        </div>
        <div className="agreement__confirm-text">
            <a href="/terms-of-use" target={'_blank'}>Согласен с Политикой обработки персональных данных</a>
        </div>
    </div>
};

const ModalOverlay = ({children}) => {
    const {context} = useContext(AppContext);

    const close = (e) => {
        if (!e.target.classList.contains('modal-overlay')) {
            return
        }

        if (context.cart.state.modal.open) {
            context.cart.dispatch({type: 'close'})
        }

        if (context.modals.restaurant.state.open) {
            context.modals.restaurant.dispatch({type: 'close'})
        }

        if (context.modals.product.state.open) {
            context.modals.product.dispatch({type: 'close'})
        }

        if (context.modals.auth.state.open) {
            context.modals.auth.dispatch({type: 'close'})
        }

        if (context.modals.action.state.open) {
            context.modals.action.dispatch({type: 'close'})
        }

        if (context.modals.vacancy.state.open) {
            context.modals.vacancy.dispatch({type: 'close'})
        }
        if (context.modals.newYear.state.open) {
            context.modals.newYear.dispatch({type: 'close'})
        }
    };

    const isOpen = () => {
        return context.modals.product.state.open
            || context.modals.restaurant.state.open
            || context.modals.auth.state.open
            || context.modals.action.state.open
            || context.modals.vacancy.state.open
            || context.cart.state.modal.open
            || context.modals.newYear.state.open
    };

    return <div onClick={(e) => {
        close(e)
    }}
                className={`modal-overlay ${isOpen() ? 'modal-overlay--open' : ''} ${context.cart.state.modal.open ? 'modal-overlay--cart' : ''}`}>{children}</div>
};
