import React, {useState} from 'react';

import arrow_down from "../static/icons/arrow_down.svg"

export const Accordeon = ({title, inner}) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    return <div className={`accordeon-item ${open ? 'accordeon-item--opened' : ''}`}>
        <div className='accordeon-item__title' onClick={() => {toggle()}}>
            <div className="accordeon-item__title-icon"><img src={arrow_down} alt=""/></div>
            <div className="accordeon-item__title-text">{title}</div>
        </div>
        <div className="accordeon-item__description">{inner}</div>
    </div>
};