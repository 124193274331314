import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/catalog`;

export class Catalog extends Base {
    category(slug) {
        return this.fetchJSON(`${BASE_URL}/${slug}`).then(response => {
            return response.data
        })
    }

    recommendations() {
        return this.fetchJSON(`${BASE_URL}/recommendations`).then(response => {
            return response.data
        })
    }

    categories() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    search(query) {
        return this.fetchJSON(`${BASE_URL}/search?query=${query}`).then(response => {
            return response.data
        })
    }
}