import React from 'react'
// import {Link} from 'react-router-dom';
import Collapse from 'react-collapse';
import YouTube from 'react-youtube';

import {AppContext} from '../../store/context';
import styles from './Kimchi.module.css';

import autumn1D from '../../static/images/landings/kimchi/autumn-1-desktop.png';
// import autumn1M from '../../static/images/landings/kimchi/autumn-1-mobile.png';
import autumn2D from '../../static/images/landings/kimchi/autumn-2-desktop.png';
// import autumn2M from '../../static/images/landings/kimchi/autumn-2-mobile.png';
import autumn3D from '../../static/images/landings/kimchi/autumn-3-desktop.png';
// import autumn3M from '../../static/images/landings/kimchi/autumn-3-mobile.png';
// import icon1D from '../../static/images/landings/kimchi/icon-1-desktop.png';
// import icon1M from '../../static/images/landings/kimchi/icon-1-mobile.png';
// import icon2D from '../../static/images/landings/kimchi/icon-2-desktop.png';
// import icon2M from '../../static/images/landings/kimchi/icon-2-mobile.png';
// import icon3D from '../../static/images/landings/kimchi/icon-3-desktop.png';
// import icon3M from '../../static/images/landings/kimchi/icon-3-mobile.png';
// import icon4D from '../../static/images/landings/kimchi/icon-4-desktop.png';
// import icon4M from '../../static/images/landings/kimchi/icon-4-mobile.png';
import infoD from '../../static/images/landings/kimchi/info-desktop.png';
// import infoM from '../../static/images/landings/kimchi/info-mobile.png';
import infoList1D from '../../static/images/landings/kimchi/info-list-1-desktop.png';
import infoList1M from '../../static/images/landings/kimchi/info-list-1-mobile.png';
import infoList2D from '../../static/images/landings/kimchi/info-list-2-desktop.png';
import infoList2M from '../../static/images/landings/kimchi/info-list-2-mobile.png';
import introD from '../../static/images/landings/kimchi/intro-desktop.png';
// import introM from '../../static/images/landings/kimchi/intro-mobile.png';
import introDOld from '../../static/images/landings/kimchi/intro-desktop-old.png';
import introIcon from '../../static/images/landings/kimchi/intro-icon.svg';
import locationD from '../../static/images/landings/kimchi/location-desktop.png';
// import locationM from '../../static/images/landings/kimchi/location-mobile.png';
import locationQuoteD from '../../static/images/landings/kimchi/location-quote-desktop.png';
import locationQuoteM from '../../static/images/landings/kimchi/location-quote-mobile.png';

export class Kimchi extends React.Component {
    constructor() {
        super();
        this.state = {
            showTextLocation: false,
        }
    }

    componentDidMount() {
        // window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
    const locationYtubeOpts = {
        width: '648',
        height: '314',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            controls: 2,
            showinfo: 0,
            rel: 0,
            modestbranding: 0,
        },
    };

    const whyYtubeOpts = {
        width: '390',
        height: '752',
        playerVars: {
            controls: 2,
            showinfo: 0,
            rel: 0,
            modestbranding: 0,
            loop: 1,
            playlist: 'BBAmmwlNYzw',
        },
    };

    const benefitYtubeOpts = {
        width: '216',
        height: '385',
        playerVars: {
            controls: 2,
            showinfo: 0,
            rel: 0,
            modestbranding: 0,
            loop: 1,
            playlist: 'BBAmmwlNYzw',
        },
    };

    const videoId1 = 'uiEYzyNKLmI';
    const videoId2 = 'BBAmmwlNYzw';

      return <>
        <div className={`${styles.kimchiLandingPage} kimchiLandingPageFix`}>

            <section className={styles.intro}>
                <div className={`${styles.introContainer} ${styles.containerLanding}`}>
                    <div className={styles.introContainerSlogan}>
                        <div className={styles.introImgSlogan}>
                            <img
                                width="61"
                                height="195"
                                src={introIcon}
                                alt=""
                            />
                        </div>
                        <h1 className={styles.introTitle}><span className={styles.introTitleBig}>Кимчи –</span><br /> главное блюдо, которое подают <span className={styles.textColorGreen}><br />к&nbsp;каждому&nbsp;столу в Корее</span></h1>
                    </div>
                    <div className={styles.introImg}>
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={introDOld}
                                width="648"
                                height="251"
                            />
                            <img
                                src={introD}
                                width="588"
                                height="325"
                                alt="Кимчи"
                            />
                        </picture>
                    </div>
                </div>
            </section>

            <section className={styles.location}>
                <div className={styles.locationImg}>
                    <picture>
                        {/* <source
                            media="(max-width: 768px)"
                            srcSet={locationM}
                            width="390"
                            height="292"
                        /> */}
                        <img
                            src={locationD}
                            width="1440"
                            height="630"
                            alt="Кимчи"
                        />
                    </picture>
                    <div className={styles.locationIntro}>
                        <blockquote className={styles.locationQuote}>
                            <p className={styles.locationQuoteText}>«Корейские бабушки учили, что капуста для кимчи должна быть&nbsp;своя»</p>
                            <cite className={styles.locationQuoteCite}>Генеральный директор Кореана Light,<br />Дарья Ким</cite>
                        </blockquote>
                    </div>
                </div>
                <div className={`${styles.locationContainer} ${styles.containerLanding}`}>
                    <div className={styles.locationInfo}>
                        <div className={styles.locationInfoHeader}>
                            <h2 className={styles.locationInfoTitle}>От&nbsp;поля до&nbsp;ресторана <span className={styles.textColorGreen}>за&nbsp;2&nbsp;недели</span></h2>
                            <div className={styles.locationInfoTextContainer}>
                                <p className={styles.locationInfoText}>Капуста, которая едет из других стран, теряет свежесть и вкусовые качества.</p>
                                <p className={styles.locationInfoText}>Мы не стали искать компромисс и приняли решение <span className={styles.textColorGreen}>сами выращивать капусту для самого вкусного кимчи</span> в Кореана Light.</p>
                            </div>
                        </div>
                        <div className={styles.locationInfoMain}>
                            <div className={styles.locationInfoMainVideo}>
                                <p className={styles.locationInfoMainVideoTitle}>Поля капусты в Волгограде</p>
                                <p className={styles.locationInfoMainVideoText}>Мы выращиваем капусту с нашими партнёрами-фермерами, которые приняли вызов добиться идеального урожая пекинской капусты.</p>
                                <div className={styles.locationInfoMainVideoItem}>
                                    <YouTube videoId={videoId1} opts={locationYtubeOpts} />
                                </div>
                            </div>
                            <div className={styles.locationInfoMainImage}>
                                <div className={styles.locationInfoMainImageItem}>
                                    <picture>
                                        <source
                                            media="(max-width: 768px)"
                                            srcSet={locationQuoteM}
                                            width="123"
                                            height="203"
                                        />
                                        <img
                                            src={locationQuoteD}
                                            width="424"
                                            height="635"
                                            alt="Капуста"
                                        />
                                    </picture>
                                </div>

                                {this.context.context.settings.isMobile ?
                                    <blockquote className={styles.locationInfoMainImageQuote}>
                                        <p className={styles.locationInfoMainImageQuoteText}><span>«Формула идеального кимчи?</span> <span>Солнце, плодородная земля, вода и твоя душа – без неё не получится вырастить вкусный урожай»</span></p>
                                        <div className={styles.locationInfoMainImageQuoteCiteWrapper}>
                                            <cite className={styles.locationInfoMainImageQuoteCite}>Денис Хон,<br /> партнёр-фермер Кореаны Light</cite>
                                        </div>
                                    </blockquote>
                                    :
                                    <blockquote
                                        className={`${styles.locationInfoMainImageQuote} ${this.state.showTextLocation ?styles.locationInfoMainImageQuoteIconUp : ''}`}
                                        onClick={() => this.setState({showTextLocation: !this.state.showTextLocation})}
                                    >
                                        <Collapse isOpened={this.state.showTextLocation}>
                                            <p className={styles.locationInfoMainImageQuoteText}>«Формула идеального кимчи? Солнце, плодородная земля, вода и твоя душа – без неё не получится вырастить вкусный урожай»</p>
                                            <div className={styles.locationInfoMainImageQuoteCiteWrapper}>
                                                <cite className={styles.locationInfoMainImageQuoteCite}>Денис Хон,<br /> партнёр-фермер Кореаны Light</cite>
                                            </div>
                                        </Collapse>
                                        {!this.state.showTextLocation &&
                                        <p className={styles.locationInfoMainImageQuoteText}>«Формула идеального кимчи?...</p>}
                                    </blockquote>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={styles.video}>
                <div className={`${styles.videoContainer} ${styles.containerLanding}`}>
                    {!this.context.context.settings.isMobile &&
                    <div className={styles.videoItem}>
                        <YouTube videoId={videoId2} opts={whyYtubeOpts} />
                    </div>}
                    <section className={styles.why}>
                        <div className={styles.whyContent}>
                            <h2 className={styles.whyTitle}>Почему Волгоград?<br /> Почему сами?</h2>
                            <ul className={`${styles.iconList} ${styles.iconListTextWhite}`}>
                                <li className={`${styles.iconListItem} ${styles.iconListItem1}`}>
                                    <h3 className={`${styles.iconListTitle} ${styles.iconListTitleIcon1}`}>поиск<br /> места</h3>
                                    <p className={styles.iconListText}>Плодородная почва под&nbsp;Волгоградом идеально подходит для выращивания капусты. Рыхлая песчаная земля обеспечивает хорошую влагопроницаемость до корней, а&nbsp;степные ветра предотвращают возникновение бактериальных заболеваний растений.</p>
                                </li>
                                <li className={`${styles.iconListItem} ${styles.iconListItem2}`}>
                                    <h3 className={`${styles.iconListTitle} ${styles.iconListTitleIcon2}`}>поиск<br /> сорта</h3>
                                    <p className={styles.iconListText}>3 года мы искали нужный сорт, выбирали семена и подбирали время засеивания, тестировали урожай. Капуста для кимчи должна быть крупной и&nbsp;тяжёлой. Это значит, что&nbsp;листья&nbsp;будут&nbsp;плотными, а&nbsp;после засолки сохранят хруст и&nbsp;не&nbsp;увянут.</p>
                                </li>
                            </ul>
                        </div>

                    </section>
                    <section className={styles.benefit}>
                        <h2 className={styles.benefitTitle}>Польза кимчи</h2>
                        <ul className={styles.iconList}>
                            <li className={`${styles.iconListItem} ${styles.iconListItem3}`}>
                                <div>
                                    <h3 className={`${styles.iconListTitle} ${styles.iconListTitleIcon3}`}>витамины</h3>
                                    <p className={styles.iconListText}>Кимчи – это не только пекинская капуста, содержащая большое количество витамина С. Это и природные «лекарства» – перец, чеснок и имбирь, входящие в состав блюда.</p>
                                </div>
                                {this.context.context.settings.isMobile &&
                                <div className={styles.iconListItemVideoItem}>
                                    <YouTube videoId={videoId2} opts={benefitYtubeOpts} />
                                </div>}
                            </li>
                            <li className={`${styles.iconListItem} ${styles.iconListItem4}`}>
                                <h3 className={`${styles.iconListTitle} ${styles.iconListTitleIcon4}`}>лактобактерии</h3>
                                <p className={styles.iconListText}>В результате ферментации пекинской капусты выделяются молочнокислые бактерии, которые известны нам как пробиотики. Эти бактерии помогают правильной работе кишечника, где сосредоточено 80% иммунных клеток<br />человека.</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>

            <section className={styles.info}>
                <div className={`${styles.infoHeaderContainer} ${styles.containerLanding}`}>
                    <div className={styles.infoHeader}>
                        <div className={styles.infoImg}>
                            <picture>
                                {/* <source
                                    media="(max-width: 768px)"
                                    srcSet={infoM}
                                    width="338"
                                    height="153"
                                /> */}
                                <img
                                    src={infoD}
                                    width="648"
                                    height="289"
                                    alt="Кимчи"
                                />
                            </picture>
                        </div>
                        <div className={styles.infoHeaderTextContent}>
                            <h2 className={styles.infoTitle}>Кимчи –<br /> всему голова</h2>
                            <p className={styles.infoText}>Значимость кимчи для корейского стола сравнима&nbsp;с&nbsp;важностью хлеба в культуре России.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.infoListWrapper}>
                    <div className={`${styles.infoListContainer} ${styles.containerLanding}`}>
                        <ul className={styles.infoList}>
                            <li className={styles.infoItem}>
                                <div className={styles.infoItemImg}>
                                    <picture>
                                        <source
                                            media="(max-width: 768px)"
                                            srcSet={infoList1M}
                                            width="266"
                                            height="180"
                                        />
                                        <img
                                            src={infoList1D}
                                            width="648"
                                            height="282"
                                            alt="Капуста и люди"
                                        />
                                    </picture>
                                </div>
                                <p className={styles.infoItemText}><span>Кимчи – обязательное блюдо любого корейского стола, даже&nbsp;на&nbsp;завтрак.</span> <span>Это&nbsp;натуральный и полезный продукт, который обеспечит эффективный заряд энергии.</span></p>
                            </li>
                            <li className={styles.infoItem}>
                                <div className={styles.infoItemImg}>
                                    <picture>
                                        <source
                                            media="(max-width: 768px)"
                                            srcSet={infoList2M}
                                            width="266"
                                            height="180"
                                        />
                                        <img
                                            src={infoList2D}
                                            width="648"
                                            height="282"
                                            alt="Капуста"
                                        />
                                    </picture>
                                </div>
                                <p className={styles.infoItemText}>Кимчи буквально спасло корейский народ в&nbsp;голодные&nbsp;времена: ферментированная капуста долго хранилась без&nbsp;холодильника и&nbsp;восполняла нехватку витаминов.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className={styles.autumn}>
                <div className={`${styles.autumnContainer} ${styles.containerLanding}`}>
                    <div className={styles.autumnHeader}>
                        <h2 className={styles.autumnTitle}>Осень –<br /> время кимчжана</h2>
                        <p className={styles.autumnText}>Сезон, когда собранный урожай капусты<br /> заготавливают на зиму, в Корее называется кимчжан.</p>
                    </div>
                    <ul className={styles.autumnList}>
                        <li className={`${styles.autumnItem} ${styles.autumnItemOnlyImage}`}>
                            <div className={styles.autumnItemImg}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={autumn1M}
                                        width="338"
                                        height="218"
                                    /> */}
                                    <img
                                        src={autumn1D}
                                        width="648"
                                        height="440"
                                        alt="Люди"
                                    />
                                </picture>
                            </div>
                        </li>
                        <li className={styles.autumnItem}>
                            <div className={styles.autumnItemImg}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={autumn2M}
                                        width="159"
                                        height="115"
                                    /> */}
                                    <img
                                        src={autumn2D}
                                        width="312"
                                        height="197"
                                        alt="Люди"
                                    />
                                </picture>
                            </div>
                            <p className={styles.autumnItemText}>Кимчжан – национальная традиция, соблюдая которую, мы сохраняем связь с предками. У каждой общины каждой семьи есть свой уникальный рецепт кимчи – это тысячи вариантов.</p>
                        </li>
                        <li className={styles.autumnItem}>
                            <div className={styles.autumnItemImg}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={autumn3M}
                                        width="159"
                                        height="115"
                                    /> */}
                                    <img
                                        src={autumn3D}
                                        width="312"
                                        height="197"
                                        alt="Люди"
                                    />
                                </picture>
                            </div>
                            <p className={styles.autumnItemText}><span>Мы объединили мудрость бабушек, знания агрономии и кулинарные эксперименты, чтобы создать идеальное кимчи.</span> <span>Приходите попробовать самое корейское блюдо в Кореана Light.</span></p>
                        </li>
                    </ul>
                </div>
            </section>

        </div>
      </>
    }
}

Kimchi.contextType = AppContext;
