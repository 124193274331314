import {useContext, useState, useEffect} from "react";

import {AppContext} from "../store/context";

import {CATALOG_SORT, CATALOG_SORT_DEFAULT} from '../const'

import arrow_down from "../static/icons/arrow_down.svg"
import radio from "../static/icons/radio.svg"
import radio_active from "../static/icons/radio_active.svg"
import place from '../static/icons/place.png'

import sort from '../static/icons/sort.svg'
import sortUp from '../static/icons/sort_arrow_up.svg'
import sortDown from '../static/icons/sort_arrow_down.svg'

export const RestaurantSimpleDropdown = ({name, selected, values, callback}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const clickFunction = (el) => {
        setIsOpen(!isOpen);

        callback(el);
    };

    return <div className='dropdown'>
        <div onClick={() => {
            toggle()
        }} className="dropdown-value">
            <span className="dropdown-value-text">{isOpen ? name : selected.title}</span>
            <span className="dropdown-value-icon"><img src={arrow_down} alt=""/></span>
        </div>
        <div className={`dropdown-inner ${isOpen ? 'dropdown-inner--open' : ''}`}>
            {values.map(el => {
                return <div onClick={() => {
                    clickFunction(el)
                }} className={`dropdown-item ${el.title === selected.title ? 'dropdown-item--active' : ''}`}>
                    <span className='dropdown-radio'>
                        {el.title === selected.title ? <img src={radio_active} alt=""/> : <img src={radio} alt=""/>}
                    </span>
                    <span className='dropdown-text'>
                        {el.title}
                    </span>
                </div>
            })}
        </div>
    </div>
};

export const RestaurantPickupDropdown = () => {
    const {context} = useContext(AppContext);
    const [selected, setSelected] = useState({
        id: 0,
        title: 'Выберите ресторан',
    })

    useEffect(() => {
        const found = context.restaurants.state.items.find(el => {
            return el.zone.id === context.cart.state.delivery.zone.id
        })

        if (found) setSelected(found)
    }, [context.cart.state.delivery, context.restaurants.state])

    const fn = (el) => {
        context.processor.cart.selectPickupRestaurant(el).then(res => {
            context.cart.dispatch({
                type: 'setZone',
                payload: {
                    zone: {
                        id: res.cart.delivery.zone.id,
                        group: res.cart.delivery.zone.group,
                        distance: res.cart.delivery.zone.distance,
                    }
                }
            });

            context.cart.dispatch({type: 'setCart', payload: res.cart});

            if (res.removed === true) {
                context.toast.info('Некоторые блюда недоступны в выбранном ресторане и мы удалили их из корзины :(');
            }
        }).catch(err => {
            context.toast.warn('Ресторан временно недоступен')
        });
    }

    return <RestaurantDropdown selected={selected} onSelect={fn}/>
};

export const RestaurantDropdown = ({selected, onSelect, fromProps, withAddress, customList}) => {
    withAddress = withAddress || false;
    fromProps = fromProps || null;
    customList = customList || null;

    const {context} = useContext(AppContext);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const clickFunction = (el) => {
        setIsOpen(!isOpen);

        onSelect(el)
    };

    const restaurants = customList ? customList : context.restaurants.state.items;

    const restaurantsFiltres = restaurants.filter(el => {
        return el.active === true;
    });

    useEffect(() => {
        if (fromProps !== null && restaurants.length > 0) {
            const found = restaurants.find(el => {
                return el.slug === fromProps
            })

            if (found) {
                onSelect(found)
            }
        }
    }, [fromProps, restaurants])

    return selected ? <div className='zone-dropdown'>
        <div onClick={() => toggle()} className="zone-dropdown-value">
            <div className="zone-dropdown-value-icon--place">
                <img src={place} alt=""/>
            </div>
            <span className="zone-dropdown-value-text">{selected.title}</span>
            <span className="zone-dropdown-value-icon--caret">
                <img src={arrow_down} alt=""/>
            </span>
        </div>
        <div className={`zone-dropdown-inner ${isOpen ? 'zone-dropdown-inner--open' : ''}`}>
            {restaurantsFiltres.map(el => {
                return <div onClick={() => clickFunction(el)}
                            className={`zone-dropdown-item ${el.id === selected.id ? 'zone-dropdown-item--active' : ''}`}>
                    <span className='zone-dropdown-radio'>
                        {el.id === selected.id ? <img src={radio_active} alt=""/> : <img src={radio} alt=""/>}
                    </span>
                    <span className={"zone-dropdown-text" + withAddress ? "zone-dropdown-with_address" : null}>
                        <div className="zone-dropdown-title">
                            {el.title}
                         </div>
                        {withAddress ? <div className="zone-dropdown-subtitle">
                            {el.address}
                        </div> : null}
                    </span>

                </div>
            })}
        </div>
    </div> : null
};

export const SortDropdown = () => {
    const {context} = useContext(AppContext);

    const selected = context.catalog.state.sort.selected || CATALOG_SORT_DEFAULT;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const clickFunction = (el) => {
        setIsOpen(!isOpen);

        context.catalog.dispatch({type: 'sort', payload: el})
    };

    let icon = null;
    switch (selected.direction) {
        case 'asc':
            icon = sortDown;
            break;
        case 'desc':
            icon = sortUp;
            break;
        default:
            icon = sort;
    }

    return <div className='dropdown sort-dropdown'>
        <div onClick={() => toggle()} className="dropdown-value">
            <div className="dropdown-value-icon">
                <img src={icon} alt=""/>
            </div>
            <span className="dropdown-value-text">{selected.value}</span>
        </div>
        <div className={`dropdown-inner ${isOpen ? 'dropdown-inner--open' : ''}`}>
            {CATALOG_SORT.map(el => {
                return <div onClick={() => {
                    clickFunction(el)
                }} className={`dropdown-item ${el.type === selected.type ? 'dropdown-item--active' : ''}`}>
                    <span className='dropdown-radio'>
                        {el.type === selected.type ? <img src={radio_active} alt=""/> : <img src={radio} alt=""/>}
                    </span>
                    <span className='dropdown-text'>
                        {el.title}
                    </span>
                </div>
            })}
        </div>
    </div>
};

export const ModifierDropdown = ({selected, values, callback}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const clickFunction = (el) => {
        setIsOpen(!isOpen);

        callback(el);
    };

    return <div className='dropdown modifier-dropdown'>
        <div onClick={() => toggle()} className="dropdown-value">
            <div className="dropdown-value-text">
                <span>{selected.title}</span>
            </div>
            <div className="dropdown-value-icon">
                <img src={arrow_down} alt=""/>
            </div>
        </div>
        <div className={`dropdown-inner ${isOpen ? 'dropdown-inner--open' : ''}`}>
            {values.map(el => {
                return <div onClick={() => {
                    clickFunction(el)
                }} className={`dropdown-item ${el.id === selected.id ? 'dropdown-item--active' : ''}`}>
                    <span className='dropdown-radio'>
                        {el.id === selected.id ? <img src={radio_active} alt=""/> : <img src={radio} alt=""/>}
                    </span>
                    <span className='dropdown-text'>
                        {el.title}
                    </span>
                </div>
            })}
        </div>
    </div>
};

export const TimeDropdown = ({values, callback}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({minutes: "00", hour: "10"});

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const selectTime = (el) => {
        setSelected(el);

        setIsOpen(false);

        callback(el);
    };

    useEffect(() => {
        if (values.length > 0) {
            selectTime(values[0]);

            return;
        }
    }, []);

    return <div className="delivery-time__selector">
        <div className="delivery-time__selector--label">
            <div>сегодня</div>
            <div>{new Date().toLocaleDateString()}</div>
        </div>
        <div className="delivery-time__selector--dropdown">
            <div className='dropdown sort-dropdown'>
                <div onClick={() => toggle()} className="dropdown-value">
                    <div className="dropdown-value-icon">
                        <img src={arrow_down} alt=""/>
                    </div>
                    <span
                        className="dropdown-value-text">{selected.hour}:{selected.minutes == 0 ? '00' : selected.minutes}</span>
                </div>
                <div className={`dropdown-inner ${isOpen ? 'dropdown-inner--open' : ''}`}>
                    {values.map(el => {
                        return <div onClick={() => {
                            selectTime(el)
                        }} className={`dropdown-item`}>
                            <span className='dropdown-text'>{el.hour}:{el.minutes == 0 ? '00' : el.minutes}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}
