import React, {useContext} from "react";
import {BottomSeo} from '../components/Seo'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import {usePageViews} from '../App'

import {
    ModalHolder,
    ModalOverlay,
    ResponsiveActionModal,
    ResponsiveAuthModal,
    ResponsiveCartModal,
    ResponsivePickupModal,
    ResponsiveProductModal
} from "../components/Modal";
import {AppContext} from "../store/context";

const Main = ({className, children}) => (
    <div className={className}>{children}</div>
);

const NoFooterLayout = ({children}) => {
    const {context} = useContext(AppContext)

    usePageViews();

    return <React.Fragment>
        <Main className="main">
            <NavBar/>
            {children}
            <BottomSeo/>
            <Footer/>
            <ModalHolder>
                <ResponsivePickupModal/>
                <ResponsiveProductModal/>
                <ResponsiveAuthModal/>
                <ResponsiveActionModal/>
            </ModalHolder>
            <ResponsiveCartModal/>
        </Main>
    </React.Fragment>
};

export default NoFooterLayout;
