import React from 'react'
import Collapse from "react-collapse";

import {AppContext} from "../../store/context";

import pibim from '../../static/images/landings/moscow/pibim.png'
import kor from '../../static/images/landings/sochi/kor.png'
import kor2 from '../../static/images/landings/sochi/kor2.png'
import sec3 from '../../static/images/landings/moscow/sec3.png'
import hit1 from '../../static/images/landings/moscow/hit1.png'
import hit2 from '../../static/images/landings/moscow/hit2.png'
import arrow from '../../static/images/landings/moscow/arrow.svg'
import sochi from '../../static/images/landings/sochi/sochi.png'
import sochi2 from '../../static/images/landings/sochi/sochi2.png'
import green from '../../static/images/landings/sochi/green.png'

import leaf from '../../static/images/landings/moscow/mobleaf.png'
import pepp from '../../static/images/landings/moscow/mobpepper.png'
import pib from '../../static/images/landings/moscow/mobpib.png'

import styles from './Sochi.module.css'
import {Link} from "react-router-dom";

import {MapLanding} from "../../components/Map"

export class Sochi extends React.Component {
    constructor() {
        super();
        this.state = {
            active: 0,
            showText: false,
        }
    }

    componentDidMount() {
        // window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <div className="about-page reveal">
            <div className="container container-pd">
                <div className={styles.intro}>
                    <h1 className={styles.title}>
                        <span className='color-black'>{"кореана light"}</span><br/>
                        <span className='color-black'>{"в cочи"}</span>
                    </h1>
                    <div className={styles.introText}>
                        <p>
                            Пробовали ли вы уже настоящую корейскую уличную еду, ту, что едят герои ваших любимых дорам?
                            Хотите бурный поток вкусовых ощущений?
                            <br/><br/>
                            Тогда ищите на втором этаже торгово-развлекательного центра «МореМолл» островок корейского
                            стрит-фуда Koreana Light. Именно здесь привычная нам корейская кухня встречается с
                            современным подходом.
                        </p>
                    </div>
                    <a href={"https://koreanalight.ru/files/menu_sochi.pdf"} target={"_blank"}
                       className={styles.introButton}>
                        <span>меню стрит-фуда в сочи</span>
                        <span>pdf</span>
                    </a>
                </div>
            </div>
            {this.context.context.settings.isMobile ? <div className={styles.mobImages}>
                <img src={pepp} alt=""/>
                <img src={leaf} alt=""/>
                <img src={pib} alt=""/>
            </div> : null}
            <div className="container container-pd">
                <div className={styles.map}>
                    <div className={styles.mapComponent}>
                        <MapLanding height={this.context.context.settings.isMobile ? "400px" : "300px"} point={{
                            lat: 43.606009,
                            lng: 39.732188,
                            name: 'ТРК МореМолл (Сочи)',
                        }}/>
                    </div>
                    <div className={`restaurant-contact restaurant-contact--show restaurant-contact--no-back`}>
                        <div className="restaurant-contact__title">
                            ТРК МореМолл (Сочи)
                        </div>
                        <div className="restaurant-contact__address text">Сочи, ул.Новая Заря 7, микрорайон Донская
                        </div>
                        <div className="restaurant-contact__phone text">
                            <a href={`tel:+7 965 484 42 73`}>+7 965 484 42 73</a>
                        </div>
                        <div className="restaurant-contact__operating text">
                            График работы: 10:00 - 22:00
                        </div>
                    </div>
                </div>
                <div className={styles.about}>
                    <h2 className={styles.title}>
                        <span className='color-black'>Меню корейского</span><br/>
                        <span className='color-black'>стрит-фуда</span>
                        <span className='color-green'> koreana Light</span>
                    </h2>
                    <div className={styles.aboutTexts}>
                        <div className={styles.text}>
                            <p>«Кореана Лайт» — это не только вкусно, а еще полезно и доступно. Мы знаем, что питание —
                                ключевой фактор здоровья, долголетия и отличного настроения, поэтому на своей кухне
                                следуем пяти правилам здорового питания. Это значит, что все блюда нашего меню
                                сбалансированы по белкам, жирам и углеводам, без консервантов, стабилизаторов и
                                искусственных красителей, обладают яркими вкусами и приготовлены с минимальной
                                термической обработкой для сохранения всех полезных свойств продуктов и витаминов.
                            </p>
                            <p>
                                В меню Koreana Light есть как корейская уличная еда, которую можно съесть на бегу —
                                кимпаб, моти, онигири, пирожки пегодя, так и блюда корейской кухни, располагающие к
                                продолжительным и душевным посиделкам с друзьями и семьей — пибимпаб, куксу,
                                корейские салаты с кимчи.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greenSections}>
                <div className={styles.greenSection}>
                    <div className={styles.greenSectionText}>
                        <h3 className={styles.titleSmall}>
                            <span className='color-black'>{"корейские "}</span>
                            <span className='color-green'>{"дошираки"}</span>
                        </h3>
                        <div className={styles.text}>
                            <Link style={{color: "inherit"}} to={"/menu/doshiraki"}>Корейский доширак</Link> —
                            это полноценный прием пищи, который идеально подходит на завтрак, обед
                            или ужин. С корейского «доширак» переводится как «еда в коробке» или «ланч-бокс».
                            <br/><br/>
                            Доширак — это вовсе не лапша быстрого приготовления, как мы привыкли думать в России.
                            Содержимое доширака всегда очень разнообразно и придется по вкусу всем гурманам и любителям
                            корейской кухни. В классический доширак обязательно должен входить салат кимчи, рис, мясо и
                            несколько закусок. В дошираках Koreana Light вы найдёте корейские салаты и закуски, а также
                            суп и горячее.
                        </div>
                    </div>
                    <img src={sec3}
                         alt="Комплексный обед доширак в корейском стрит фуд кафе Кореана Лайт в Сочи"/>
                </div>
            </div>
            <div className={styles.hits}>
                <h3 className={styles.title}>
                    <span className='color-black'>{"Абсолютные хиты корейского стрит-фуда "}</span>
                    <span className='color-green'>{"koreana Light"}</span>
                </h3>
                <div className={styles.hitsImages}>
                    <img src={hit1} alt="Пибимпаб в корейском кафе Koreana Light в Сочи"/>
                    <img src={hit2} alt="Бургер ким из риса в стрит-фуде корейской кухни Кореана Light в Сочи"/>
                </div>
                <div className={styles.hitsMenus}>
                    {this.context.context.settings.isMobile ? <img src={leaf} alt=""/> : null}
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 0})}>
                                <span>Пибимпабы</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"menu/bluda-s-risom"} className={styles.hitMenuButton}>
                                    меню пибимпабы
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 0} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Невероятно красивое и вкусное корейское блюдо, которое в последнее время завоевывает
                                сердца гурманов по всему миру. Это настолько впечатляющее блюдо, что попало в топ-50
                                лучших в мире по версии CNN.. Оно готовится из разнообразных ингредиентов, таких как
                                рис, мясо, овощи, яйца и специи, которые перемешиваются перед употреблением.
                                <br/><br/>
                                Пибимпаб содержит много свежих овощей и зелени, что делает его богатым на витамины и
                                минералы. К тому же блюдо отличается низким содержанием жиров и углеводов, поэтому
                                является отличным выбором для людей, следящих за своей фигурой.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"menu/bluda-s-risom"} className={styles.hitMenuButton}>
                                    меню пибимпабы
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 1})}>
                                <span>БУРГЕР КИМ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"menu/byrger-kim"} className={styles.hitMenuButton}>
                                    меню бургеры ким
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 1} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Представляем вам наш супер фастфуд — вкусный и полезный <Link to={"/menu/byrger-kim"}>«Бургер
                                Ким»</Link>. Вместо
                                традиционных булочек, здесь используются две мягкие подушки из риса, который не содержит
                                глютен. Этот вкусный и полезный бургер сбалансирован по белкам, жирам и углеводам,
                                потому что мы готовим его из мраморной говядины, курицы или лосося, а также из свежих
                                овощей, уложенных слоями.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"menu/byrger-kim"} className={styles.hitMenuButton}>
                                    меню бургеры ким
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 2})}>
                                <span>САЛАТ КИМЧИ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    меню cалаты
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 2} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Гастрономическое знакомство с Кореей стоит начать с кимчи. Эта корейская национальная
                                закуска известна во всем мире благодаря своему пикантному вкусу и многочисленным
                                полезным свойствам. Мы готовим кимчи из пекинской капусты, которая ферментируется в
                                специальной смеси из перца чили, чеснока и других специй. Благодаря своему богатому
                                составу, кимчи укрепляет иммунную систему, улучшает пищеварение, снижает уровень
                                холестерина в крови, а также улучшает состояние кожи.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    меню cалаты
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 3})}>
                                <span>КИМПАБЫ - КОРЕЙСКИЕ РОЛЛЫ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 3} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Кимпаб — это легкое и вкусное блюдо, которым можно наслаждаться в любое время суток.
                                Кимпаб похож на японский ролл, но рис в нем заправляют не уксусом, а кунжутным маслом. В
                                качестве начинки мы используем кусочки сочной говядины, тунец, свежие и ферментированные
                                овощи. Когда вы чувствуете хандру и серость, закажите кимпаб — это настоящее лекарство
                                для души. Рекомендуем наслаждаться им хотя бы пару раз в неделю, чтобы утолить
                                потребность в ярких красках и витаминах.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </Collapse>
                    </div>
                    <div className={styles.hitMenu}>
                        <div className={styles.hitMenuHead}>
                            <div className={styles.hitMenuTitle} onClick={() => this.setState({active: 4})}>
                                <span>ПАРОВЫЕ ПИРОЖКИ ПЕГОДЯ</span>
                                <img src={arrow} alt=""/>
                            </div>
                            {this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </div>
                        <Collapse isOpened={this.state.active === 4} theme={{collapse: styles.collapse}}>
                            <div className={styles.hitMenuText}>
                                Ещё один представитель полезной и вкусной корейской уличной еды — паровой пирожок
                                пегодя. Сделанный из дрожжевого теста и разных начинок, например свинины и кимчи или
                                свинины и белокочанной капусты, он прекрасно насыщает и позволяет быстро переключиться с
                                рабочего режима на отдых.
                            </div>
                            {!this.context.context.settings.isMobile ? null :
                                <Link to={"/menu/salati-i-zakyski"} className={styles.hitMenuButton}>
                                    корейские закуски
                                </Link>}
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className={styles.menu}>
                <div className={styles.menuTexts}>
                    <div className={styles.menuPreTitle}>
                        Корейская кухня — это правильный баланс белков, жиров и углеводов.
                    </div>
                    <div className={styles.menuText}>
                        <div className={styles.text}>
                            Это акцент на ферментированных продуктах, которые полезны для пищеварения. Это баланс
                            соленого,
                            кислого, сладкого и острого.
                            <br/><br/>
                            Это бережные технологии приготовления, позволяющие сохранить максимум полезных веществ и
                            витаминов, которые уравновешиваются в одном блюде. Но можно долго рассказывать, а можно
                            просто
                            посетить Koreana Light.
                        </div>
                        {this.context.context.settings.isMobile ?
                            <div className={styles.menuImage}>
                                <img src={kor2}
                                     alt="Пибимпаб и баланс пяти стихий, здоровая и полезная еда в Koreana Light в Сочи"/>
                            </div> : null
                        }
                        <h2 className={styles.title}>
                            <span className='color-green'>{"Koreana Light"}</span><br/>
                            <span className='color-black'>{"корейская еда плюс современность"}</span>
                        </h2>
                        <div className={styles.text}>
                            <p>
                                Сочинский стрит-фуд ресторан корейской кухни Koreana Light, как и все представители
                                сети,
                                радует не только вкусовые рецепторы. Оно дарит счастье всем органам чувств. Глаза
                                влюбляются
                                в подачу, например, в сочетание белого, желтого, оранжевого и зелёного в том же
                                пибимпабе
                                (символизм в корейской кухне много значит). Довольным останется даже осязание —
                                настолько
                                объемны и увесисты порции.
                            </p>
                        </div>
                        <div className={styles.aboutShow}
                             onClick={() => this.setState({showText: !this.state.showText})}>
                            {this.state.showText ? "Скрыть" : "Раскрыть"}
                        </div>
                        <Collapse isOpened={this.state.showText} theme={{collapse: styles.collapse}}>
                            <div className={styles.text}>
                                <p>Корейский стрит-фуд это одна из фишек Южной Кореи, что обеспечивает Страну Утренней
                                    Свежести постоянным потоком туристов. Одна из главных достопримечательностей Сеула —
                                    рынок в парке у реки Ханган. И Koreana Light в Сочи однозначно побратим тех
                                    колоритных
                                    торговых точек. Как и они, этот ресторанчик расположен рядом с рекой, которая чуть
                                    западнее впадает в Черное море.
                                    <br/><br/>
                                    Так что теперь не только море и климат, и (надеемся😉) корейская кухня будут
                                    притягивать
                                    туристов в Сочи. Но ресторан уличной корейской еды Koreana Light нацелен не на
                                    мимолетный опыт — он стремится сделать каждого клиента завсегдатаем. И более того,
                                    уже
                                    доказал свой талант в этом деле. Вежливое и душевное обслуживание — лучшая приправа
                                    к
                                    блюдам.
                                </p>
                            </div>
                        </Collapse>
                    </div>
                </div>
                {this.context.context.settings.isMobile ? null :
                    <div className={styles.menuImage}>
                        <img src={kor}
                             alt="Пибимпаб и баланс пяти стихий, здоровая и полезная еда в Koreana Light в Сочи"/>
                    </div>}
            </div>
            {this.context.context.settings.isMobile ?
                <img src={sochi2} alt="Корейский стрит фуд в Сочи Кореана Лайт"/>
                : <div className={styles.disclamerImages}>
                    <img src={sochi} alt="Корейский стрит фуд в Сочи Кореана Лайт"/>
                    <img src={green} alt=""/>
                </div>}
            <div className={styles.disclamer}>
                <h2 className={"title"}>
                    <span className='color-black'>{"Корейское гостеприимство"}</span><br/>
                    <span className='color-black'>{"в стрит-фуд ресторане"}</span><br/>
                    <span className='color-green'>{"Koreana Light в Сочи"}</span>
                </h2>
                <div className={styles.disclamerSection}>
                    <div className={styles.disclamerText}>
                        <p className={styles.text}>Сотрудники Koreana Light в Сочи обслуживают гостей с 10:00 до 22:00.
                            Вкусная, полезная и сбалансированная еда, которую не нужно долго ждать, чуткая обратная
                            связь и внимание к отзывам и пожеланиям посетителей — основные фишки заведения. Где бы вы ни
                            посетили стрит фуд кафе Koreana Light — в Сочи, Москве или Санкт-Петербурге, вас встретит
                            уютная атмосфера, отличный ненавязчивый сервис, а самое главное, тот самый всемирно
                            известный корейский стрит-фуд, который вы видели в своих любимых дорамах!
                        </p>
                        <div className={styles.disclamerButtons}>
                            <Link to={"/menu"} className={styles.disclamerButtonGreen}>открыть меню</Link>
                            <Link to={"/vacancies"} className={styles.disclamerButtonWhite}>вакансии</Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }
}

Sochi.contextType = AppContext;