import React, {createRef, useContext, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Button} from "../components/Buttons";
import {AppContext} from "../store/context";
import InputMask from "react-input-mask";

import search from '../static/icons/search.svg'
import search_green from '../static/icons/search_green.svg'
import girlDesktop from '../static/images/vacancies/girl_desctop.jpg'
import girlMobile from '../static/images/vacancies/girl_mob.jpg'
import attach from '../static/images/vacancies/attach.png'

export const FeedbackForm = ({title}) => {
    const {context} = useContext(AppContext);

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [comment, setComment] = useState(null);
    const [email, setEmail] = useState(null);

    const [proceed, setProceed] = useState(false);

    title = title || 'Остались вопросы?';

    const processForm = () => {
        if (!comment || !phone || !name || !email) {
            context.toast.error('Пожалуйста, проверьте корректность введенных данных');
            return;
        }

        if(!(/\S+@\S+\.\S+/.test(email))) {
            context.toast.error('Пожалуйста, проверьте корректность введенного email');
            return;
        }

        if (name.length < 2) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (phone.match(/\d+/g).join('').length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }


        if (comment.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность введенного комментария');
            return;
        }

        const data = {
            name: name,
            phone: phone,
            email: email,
            comment: comment
        };

        context.processor.forms.callback(data).then(res => {
            setProceed(true)
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return <div className="feedback-form">
        <div className="feedback-form__title">
            <div className="title">
                <span className='color-black'>{title}</span>
            </div>
        </div>
        {proceed ?
            <div className="feedback-form__text text text--faded">
                Ваше сообщение отправлено! Мы свяжемся с вами в ближайшее время
            </div>
            :
            <>
                <div className="feedback-form__text text text--faded">
                    Обязательно напишите нам, если у вас остались вопросы или замечания, мы ответим вам в
                    самое ближайшее время!
                </div>
                <div className="feedback-form__form">
                    <Input type="text" placeholder={'Имя'} value={name} onChange={(e) => {
                        setName(e.currentTarget.value)
                    }}/>
                    <InputMask className={"input input--text"}
                               value={phone}
                               formatChars={{
                                   '9': '[0-9]',
                                   '7': '[7-8]'
                               }}
                               onChange={e => setPhone(e.currentTarget.value)}
                               mask="+7 (999) 999-99-99"
                               placeholder={'Телефон'}
                               maskChar="_"/>
                    <Input type="text" placeholder={'Email'} value={email} onChange={(e) => {
                        setEmail(e.currentTarget.value)
                    }}/>
                    <Textarea placeholder={'Сообщение'} value={comment} onChange={(e) => {
                        setComment(e.currentTarget.value)
                    }}/>
                </div>
                <div className="feedback-form__button">
                    <Button color={'green'} text={'Отправить'} onClick={() => {
                        processForm()
                    }}/>
                </div>
            </>
        }
    </div>
};

export const VacanciesFormInner = ({title}) => {
    const {context} = useContext(AppContext);

    const fileRef = createRef();

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [comment, setComment] = useState(null);
    const [file, setFile] = useState(null);

    const [proceed, setProceed] = useState(false);

    const processForm = () => {
        if (!comment || !phone || !name) {
            context.toast.error('Пожалуйста, проверьте корректность введенных данных');
            return;
        }

        if (name.length < 2) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (phone.match(/\d+/g).join('').length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (comment.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность введенного комментария');
            return;
        }

        const formData = new FormData();
        formData.append('title', title || '-');
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('comment', comment);
        formData.append('file', fileRef.current.files[0]);

        context.processor.forms.vacancy(formData).then(res => {
            setProceed(true)
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return !proceed ? <div className="vacancies-form__form">
        <div className="vacancies-form__form-row">
            <Input type="text" placeholder={'ФИО'} value={name} onChange={(e) => {
                setName(e.currentTarget.value)
            }}/>
        </div>
        <div className="vacancies-form__form-row">
            <InputMask className={"input input--text"}
                       value={phone}
                       formatChars={{
                           '9': '[0-9]',
                           '7': '[7-8]'
                       }}
                       onChange={e => setPhone(e.currentTarget.value)}
                       mask="+7 (999) 999-99-99"
                       placeholder={'Телефон'}
                       maskChar="_"/>
            <Input type="text" placeholder={'Email'} value={email} onChange={(e) => {
                setEmail(e.currentTarget.value)
            }}/>
        </div>
        <div className="vacancies-form__form-row">
            <Textarea placeholder={'Сопроводительное письмо'} value={comment} onChange={(e) => {
                setComment(e.currentTarget.value)
            }}/>
        </div>
        <div className="vacancies-form__attach" onChange={(e) => {
            return e.target.files.length ? setFile(e.target.files[0].name) : null
        }} onClick={() => {
            fileRef.current.click()
        }}>
            <input style={{width: "1px", height: "1px", visibility: "hidden", position: "absolute"}} ref={fileRef}
                   type="file"/>
            <div className="vacancies-form__attach--icon"><img src={attach} alt=""/></div>
            <div className="vacancies-form__attach--text">{file ? file : "Прикрепить резюме"}</div>
        </div>
        <div className="vacancies-form__button">
            <Button color={'green'} text={'Отправить'} onClick={() => {
                processForm()
            }}/>
        </div>
    </div> : <div className="vacancies-form__text text text--faded">
        Ваше сообщение отправлено! Мы свяжемся с вами в ближайшее время
    </div>
}
export const VacanciesForm = () => {
    const {context} = useContext(AppContext);

    return <div className="vacancies-form">
        <div className="vacancies-form__image">
            <img src={ context.settings.isMobile ?  girlMobile : girlDesktop } alt=""/>
        </div>
        <div className="vacancies-form__inner">
            <div className="vacancies-form__title">
                <div className="title">
                    <span className='color-black'>Оставьте заявку</span>
                </div>
            </div>

            <div className="vacancies-form__text text text--faded">
                Не нашли в списке нужную вакансию, но хотели бы стать частью нашей команды. Оставьте заявку и
                прикрепите резюме, наш менеджер свяжется с вами!
            </div>
            <VacanciesFormInner/>
        </div>
    </div>
};


export const Input = ({value, type, placeholder, name, onChange}) => {
    return <input className={`input input--${type}`} value={value} type={type} placeholder={placeholder} name={name}
                  onChange={onChange}/>
};

export const Textarea = ({placeholder, name, onChange}) => {
    return <textarea className={`input input--textarea`} placeholder={placeholder} name={name} onChange={onChange}/>
};

export const SearchHeaderInput = ({open}) => {
    const {context} = useContext(AppContext);
    const [query, setQuery] = useState(null);

    const formRef = useRef(null);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!query || !query.length) {
            return
        }

        history.push(`/menu?search=${query}`);

        context.catalog.dispatch({type: 'setQuery', payload: query})
    };

    return <div className={`search-modal ${open ? 'search-modal--open' : ''}`}>
        <form ref={formRef} onSubmit={handleSubmit}>
            <input className={`input input--text`} type={"text"} placeholder={"Поиск"} name={"query"} onChange={(e) => {
                setQuery(e.currentTarget.value)
            }}/>
            <button className={'search-modal__button'} type="submit"><img src={search_green} alt=""/></button>
        </form>
    </div>
};

export const SearchInput = () => {
    const {context} = useContext(AppContext);

    const [query, setQuery] = useState(context.catalog.state.query);
    const [tm, setTm] = useState(null);

    const history = useHistory();
    const callback = (e) => {
        const value = e.currentTarget.value;

        clearTimeout(tm);

        setTm(setTimeout(() => {
            history.push(`/menu?search=${value}`);

            context.catalog.dispatch({type: 'setQuery', payload: value})
        }, 1000));

        setQuery(value);
    };

    return <div className={`search-input`}>
        <div className="search-input--icon">
            <img src={search} alt=""/>
        </div>
        <div className="search-input--input">
            <Input type={'text'} placeholder={'Поиск'} name={'query'} value={query} onChange={callback}/>
        </div>
    </div>
};
