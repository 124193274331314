export const Loader = () => {
    return <div className={`loader`}>
        <div className="lds-ring">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
};

export const LoaderFullsize = () => {
    return <div className={`loader loader-fullsize`}>
        <div className="lds-ring">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
};