import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/forms`;

export class Forms extends Base {
    callback(data) {
        return this.fetchJSON(`${BASE_URL}/callback`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        });
    }

    vacancy(data) {
        return this.fetchJSON(`${BASE_URL}/vacancy`, {
            method: 'POST',
            body: data,
        }, true).then(response => {
            return response.data
        });
    }

    service(data) {
        return this.fetchJSON(`${BASE_URL}/service`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(response => {
            return response.data
        });
    }
}
