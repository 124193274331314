import alfa from '../../static/images/content/alfa.png'
import visa from '../../static/images/content/visa.png'
import mir from '../../static/images/content/mir.jpg'
import mastercard from '../../static/images/content/mastercard.png'

import {Title} from "../../components/Typo";

export const Confidential = () => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});

    return <div className="container container-pd confidential">
        <Title black={"Политика конфиденциальности"}/>
        <p>Оставляя данные на сайте, Вы соглашаетесь с Политикой конфиденциальности
            и защиты информации.</p>
        <strong>Защита данных</strong>
        <p>Администрация сайта https://new.koreanalight.ru (далее Сайт) не может передать или раскрыть информацию,
            предоставленную пользователем (далее Пользователь) при регистрации и использовании функций сайта третьим
            лицам, кроме случаев, описанных законодательством страны, на территории которой пользователь ведет свою
            деятельность.</p>
        <strong> Получение персональной информации</strong>
        <p> Для коммуникации на сайте пользователь
            обязан внести некоторую персональную информацию. Для проверки
            предоставленных данных, сайт оставляет за собой право потребовать доказательства идентичности в онлайн или
            офлайн режимах.</p><strong> Использование персональной информации</strong><p>
        Сайт использует личную информацию Пользователя для обслуживания и для улучшения качества предоставляемых
        услуг.
        Часть персональной информации может быть предоставлена банку или платежной системе, в случае, если
        предоставление этой информации обусловлено процедурой перевода средств платежной системе, услугами которой
        Пользователь желает воспользоваться. Сайт прилагает все усилия для сбережения в сохранности личных данных
        Пользователя. Личная информация может быть раскрыта в случаях, описанных законодательством, либо когда
        администрация сочтет подобные действия необходимыми для соблюдения юридической процедуры, судебного
        распоряжения
        или легального процесса необходимого для работы Пользователя с Сайтом. В других случаях, ни при каких
        условиях,
        информация, которую Пользователь передает Сайту, не будет раскрыта третьим лицам.
    </p>
        <strong> Коммуникация</strong>
        <p>После того, как Пользователь оставил данные, он получает сообщение,
            подтверждающее его успешную регистрацию.
            Пользователь имеет право в любой момент прекратить получение информационных бюллетеней воспользовавшись
            соответствующим сервисом в Сайте.</p>
        <strong> Ссылки</strong>
        <p>На сайте могут содержаться ссылки на другие сайты. Сайт не несет ответственности за содержание, качество и
            политику безопасности этих сайтов. Данное заявление о конфиденциальности относится только к информации,
            размещенной непосредственно на сайте.</p>
        <strong>Безопасность</strong>
        <p>Сайт обеспечивает безопасность учетной записи Пользователя от несанкционированного доступа.</p>
        <strong>Уведомления об изменениях</strong>
        <p>Сайт оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных уведомлений.
            Нововведения вступают в силу с момента их опубликования. Пользователи могут отслеживать изменения в Политике
            конфиденциальности самостоятельно.</p>
        <strong>Правила оплаты и безопасность платежей, конфиденциальность информации</strong>
        <p>Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК».</p>
        <div>
            <img src={alfa}/>
        </div>
        <p>К оплате принимаются карты VISA, MasterCard, МИР.</p>
        <div style={{display: "flex"}}>
            <div><img style={{maxWidth: "100px", marginRight: "20px"}} src={visa} alt="Visa"/></div>
            <div><img style={{maxWidth: "60px", marginRight: "20px"}} src={mastercard}/></div>
            <div><img style={{maxWidth: "120px"}} src={mir}/></div>
        </div>
        <p>Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa,
            MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения
            платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым
            каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице АО
            «АЛЬФА-БАНК».</p>

        <p>На странице для ввода данных банковской карты потребуется ввести данные банковской
            карты: номер карты, имя
            владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код
            Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код
            безопасности — это три цифры, находящиеся на обратной стороне карты.</p><p>Далее вы будете перенаправлены на
        страницу Вашего банка для ввода кода безопасности, который придет к Вам в
        СМС. Если код безопасности к Вам не пришел, то следует обратиться в банк выдавший Вам карту.</p><p>Случаи отказа
        в совершении платежа:
    </p>
        <ol>
            <li>банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать,
                обратившись в Ваш Банк;
            </li>
            <li>недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы
                можете узнать, обратившись в банк, выпустивший банковскую карту;
            </li>
            <li>данные банковской карты введены неверно;</li>
            <li>истек срок действия банковской карты.</li>
        </ol>
        Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна
        карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту

        <p>
        По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете
        обращаться
        по следующим телефонам: +7 (812) 490-74-64 (Указывается телефон Интернет-магазина).
        Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является
        конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном
        виде
        и не сохраняются на нашем Web-сервере.
    </p><strong> Правила возврата товара</strong><p>
        При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата регулируется
        правилами международных платежных систем.
        Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав
        потребителей».</p><p>
        Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных
        средств»,
        которое высылается по требованию компанией на электронный адрес и оправить его вместе с приложением копии
        паспорта по адресу koreana.light@gmail.com
    </p><p>
        Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня
        со дня получения «Заявление о возврате денежных средств» Компанией.
        Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным
        заявлением
        и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление
        необходимо направить по адресу koreana.light@gmail.com
    </p><p>
        Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств
        начинает
        исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета
        праздников/выходных дней.
    </p>
        <strong>Реквизиты компании</strong>
        <p>
            ООО «Кореана Лайт» <br/>
            ИНН: 7838061759 <br/>
            КПП: 784201001 <br/>
            ОГРН: 1167847440903 <br/>
            Юр. адрес: 191036, г. Санкт-Петербург, Невский проспект, дом 95 литер а, помещение 1-н <br/>
            Телефон: +7 (812) 490-74-64 <br/>
            e-mail: koreana.light@gmail.com <br/>
        </p>
        <p>
            <a href="/upload/files/Оферта-ИП-Ландарев.docx">Договор оферты ИП Ландарев (.docx, 25KB)</a><br/>
            <a href="/upload/files/Оферта-Кореана-Лайт.docx">Договор оферты ООО «Кореана Лайт» (.docx, 25KB)</a><br/>
        </p>
    </div>
}
